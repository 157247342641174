import { createContext } from "react";

import { QuestionAnswers } from "./types";

export const QuizContext = createContext<{
  onChange: (value: Partial<QuestionAnswers>) => void;
  answers: Partial<QuestionAnswers>;
}>({
  onChange: () => {},
  answers: {},
});
