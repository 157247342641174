import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React, { useEffect } from "react";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import SignUpForm, { SignUpFormProps } from "components/forms/SignUpForm";

type SignUpWithRedirectProps = Omit<SignUpFormProps, "onComplete"> & {
  redirect?: string;
  onComplete?: () => void;
};

export const SignUpWithRedirect = ({
  redirect,
  onComplete,
  ...props
}: SignUpWithRedirectProps) => {
  const { user, loading: loadingUser } = useAuthenticatedUser({
    redirectToLogin: false,
  });

  useEffect(() => {
    if (user && redirect) {
      window.location.href = redirect;
    }
  }, [user, redirect]);

  if (loadingUser) {
    return null;
  }

  return (
    <SignUpForm
      {...props}
      onComplete={() => {
        if (onComplete) {
          onComplete();
        }
      }}
    />
  );
};
