import styled from "styled-components";
import tw from "twin.macro";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";

const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

export const HowWeHelp = () => {
  return (
    <div tw="w-full text-center">
      <Heading tw="text-primary-500 ">This Is How We Help</Heading>
      <Paragraph>
        Our methodology mixes steady execution and knowledge through learning
        and experimentation.
      </Paragraph>
      <Paragraph>
        Think of it as if the tortoise and the hare in Aesop's fable would not
        compete with each other, but team up to win.
      </Paragraph>
      <Paragraph>
        We instill this approach regardless of where you are and far you want to
        go.
      </Paragraph>
    </div>
  );
};
