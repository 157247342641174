import Footer from "components/footers/footer";
import { Header } from "components/headers";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React, { useEffect } from "react";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useAppDispatch, useAppSelector } from "store/types";
import {
  fetchStartupSupporters,
  selectStartupSupporters,
} from "store/startupSupporters/slice";
import { StartupSupporterQuestions } from "components/startupSupporter/StartupSupporterQuestions";
import { StartupSupporterUsers } from "components/startupSupporter/StartupSupporterUsers";

const OneColumn = tw.div`max-w-screen-xl mx-auto py-8 md:py-8`;
const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;

export default function StartupSupporterDashboardPage() {
  const { user } = useAuthenticatedUser();
  const dispatch = useAppDispatch();

  const startupSupporters = useAppSelector(selectStartupSupporters);

  useEffect(() => {
    if (user) {
      dispatch(fetchStartupSupporters());
    }
  }, [user]);

  return (
    <AnimationRevealPage disabled>
      <Header></Header>
      <div tw="relative">
        <OneColumn>
          {startupSupporters.map((startupSupporter, i) => (
            <div key={i}>
              <Heading>{startupSupporter.name} Questions</Heading>
              <StartupSupporterQuestions
                startupSupporterId={startupSupporter.id}
              />
              <Heading>Users</Heading>
              <StartupSupporterUsers startupSupporterId={startupSupporter.id} />
            </div>
          ))}
        </OneColumn>
      </div>
      <Footer></Footer>
    </AnimationRevealPage>
  );
}
