import styled from "styled-components";
import tw from "twin.macro";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import MoritzStellmacherImage from "images/testimonials/moritz-stellmacher.png";

import MariaCastellonImage from "images/testimonials/maria-castellon.jpg";
import HowardMorganImage from "images/testimonials/howard-morgan.jpg";
import NeilDixitImage from "images/testimonials/neil-dixit.jpg";
import GabbiOrtigoniPlaceholderImage from "images/testimonials/placeholder1.png";

import { SimplePrimaryBackground } from "components/testimonials/SimplePrimaryBackground";

interface TestimonialDS {
  problemStatement: string;
  customerName: string;
  customerProfile: string;
  imageSrc: string;
  quote: string;
}

const testimonials: TestimonialDS[] = [
  {
    problemStatement:
      "Defining the business model of a peer to peer educational platform.",
    quote:
      '"Thank you for your guidance Michael, especially your dedication to narrowing down the value proposition to its essence and building the company communication around it has been proven to be invaluable." ',
    customerName: "Moritz Stellmacher",
    customerProfile: "Dacade.org",
    imageSrc: MoritzStellmacherImage,
  },
  {
    problemStatement:
      "Focusing the business on growth and evaluating acquisitions.",
    quote:
      "This year is going to be one of our best years in the 18 years in business ...this is due to more business coming our way..  Again, thank you for helping me to evaluate the deal with the competitor.",
    customerName: "Maria Castellon",
    customerProfile: "CEO/Founder Bench-Tek Solutions",
    imageSrc: MariaCastellonImage,
  },
  {
    problemStatement: "How to scale a Salesforce educational business.",
    quote:
      "Thanks for everything so far. I can't believe how fast business has been taking off for me at this point. I do agree that I need to keep my focus on the forefront and haven't let that out of my sight.",
    customerName: "Howard Morgan",
    customerProfile: "COO CRMX Consulting",
    imageSrc: HowardMorganImage,
  },

  {
    problemStatement: "Go-to-market strategy.",
    quote:
      "We've been running some tiny market research to validate what types of users to target (as per our discussion a few weeks ago, which was incredibly helpful).",
    customerName: "Neil Dixit",
    customerProfile: "Founding Partner Glimpse",
    imageSrc: NeilDixitImage,
  },
  {
    problemStatement: "Redefining the vision and the go-to-market strategy.",
    quote:
      "I was able to share the new vision statement with the team on Friday, and they all loved it!",
    customerName: "Gabbi Ortigoni",
    customerProfile: "President Hispanic Chamber of Commerce of Metro Orlando",
    imageSrc: GabbiOrtigoniPlaceholderImage,
  },
];

export const HomepageTestimonials = () => {
  return (
    <SimplePrimaryBackground
      description=""
      testimonials={testimonials}
    ></SimplePrimaryBackground>
  );
};
