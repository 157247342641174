import React from "react";
import { selectQuizSpec } from "store/quizzes/slice";
import { useAppSelector } from "store/types";
import { QuizResponseAnswerModel } from "store/quizzes/schemas";
import { getAnswerText } from "helpers/quiz";

type QuizAnswersProps = {
  specId: string;
  answers: Record<string, QuizResponseAnswerModel[]>;
  questions: string[];
};
export const QuizAnswers = ({
  specId,
  answers,
  questions,
}: QuizAnswersProps) => {
  const spec = useAppSelector((state) => selectQuizSpec(state, specId));

  return (
    <React.Fragment>
      {questions.map((q, i) => {
        const questionSpec = spec?.questions.find((q1) => q1.id === q);
        const questionAnswers = answers[q] ?? [];
        const answerText = getAnswerText(questionAnswers, questionSpec);

        return (
          <div key={i}>
            <p>
              <strong>
                {questionSpec?.title} {/* {questionSpec?.description} */}
              </strong>
            </p>
            <p>{answerText}</p>
          </div>
        );
      })}
    </React.Fragment>
  );
};
