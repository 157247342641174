import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Modal from "react-modal";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/configureStore";
import Firebase from "helpers/Firebase";

Modal.setAppElement("#root");

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Firebase>
        <App />
      </Firebase>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
