import tw from "twin.macro";
import styled from "styled-components";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { selectAuthToken } from "store/auth/slice";
import { fetchProduct, selectProduct } from "store/products/slice";
import { useAppDispatch, useAppSelector } from "store/types";
import { ProductType } from "./ProductType";
import { getStripeCallbackUrls, redirectToStripe } from "helpers/Stripe";

const BaseButton = tw.button`inline-block font-bold my-4 sm:my-2 rounded-lg py-6 px-4 mr-6 sm:leading-none focus:outline-none transition duration-300`;

const SmallButton = styled(BaseButton)((props: { disabled?: boolean }) => [
  tw`sm:w-40`,
  props.disabled
    ? tw`bg-gray-500 text-gray-100 cursor-default`
    : tw`bg-primary-500 text-gray-100 hover:bg-primary-900`,
]);
const ExpandedButton = styled(BaseButton)((props: { disabled?: boolean }) => [
  tw`w-full`,
  props.disabled
    ? tw`bg-gray-500 text-gray-100 cursor-default`
    : tw`bg-primary-500 text-gray-100 hover:bg-primary-900`,
]);

type PurchaseProductProps = {
  productId: string;
  label?: string;
  labelFn?: (product: {
    id: string;
    name: string;
    description: string;
    price: string;
  }) => string;
  debug?: boolean;
  expanded?: boolean;
  type: ProductType;
};

export const PurchaseProduct = ({
  productId,
  label,
  labelFn,
  debug,
  expanded,
  type,
}: PurchaseProductProps) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  const authToken = useAppSelector(selectAuthToken);

  useEffect(() => {
    dispatch(fetchProduct(productId)).then(() => setLoading(false));
  }, []);

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();

      const stripeCallbackUrls = getStripeCallbackUrls(type);

      await redirectToStripe(
        authToken ?? "",
        productId,
        stripeCallbackUrls.successUrl,
        stripeCallbackUrls.cancelUrl
      );
    },
    [type, productId, authToken]
  );

  const product = useAppSelector((state) => selectProduct(state, productId));

  const price = product ? `$${(product.priceDecimal / 100).toFixed(2)}` : "";

  const buttonLabel = useMemo(() => {
    if (labelFn) {
      return labelFn({
        id: product?.id ?? "",
        name: product?.name ?? "",
        description: product?.description ?? "",
        price,
      });
    }

    return label ?? "Checkout";
  }, [label, labelFn, product, price]);

  if (loading) {
    return null;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <section>
      {debug && (
        <div>
          {product.imageUrl && (
            <img src={product.imageUrl} alt={product.description} />
          )}
          <div>
            <h1>{product.name}</h1>
            <h3>{product.description}</h3>
            <h5>{price}</h5>
          </div>
        </div>
      )}
      <form onSubmit={onSubmit}>
        {!expanded && (
          <SmallButton type="submit" disabled={!authToken}>
            {buttonLabel}
          </SmallButton>
        )}
        {expanded && (
          <ExpandedButton type="submit" disabled={!authToken}>
            {buttonLabel}
          </ExpandedButton>
        )}
      </form>
    </section>
  );
};
