import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/configureStore";
import { createApiCall } from "store/fetch";
import {
  QuizResponseModel,
  QuizSpecificationModel,
  ProvisionalQuizResponseModel,
} from "./schemas";
import type { CollectionResultModel } from "store/types";

const initialState: {
  specs: QuizSpecificationModel[];
  responses: QuizResponseModel[];
  provisionalResponses: ProvisionalQuizResponseModel[];
} = { specs: [], responses: [], provisionalResponses: [] };

export const fetchQuizSpecs = createApiCall<
  CollectionResultModel<QuizSpecificationModel>
>("quizzes/fetchQuizSpecs", "GET", () => "/quiz-specifications");

export const fetchAllQuizResponses = createApiCall<
  CollectionResultModel<QuizResponseModel>
>("quizzes/fetchAllQuizResponses", "GET", () => "/quiz-responses");

export const fetchAllProvisionalQuizResponses = createApiCall<
  CollectionResultModel<ProvisionalQuizResponseModel>
>(
  "quizzes/fetchAllProvisionalQuizResponses",
  "GET",
  () => "/provisional-quiz-responses"
);

export const fetchQuizResponses = createApiCall<
  CollectionResultModel<QuizResponseModel>
>(
  "quizzes/fetchQuizResponses",
  "GET",
  () => "/profiles/current/quiz-responses"
);

export const upsertProvisionalQuizResponse = createApiCall<
  void,
  { id: string; body: Partial<ProvisionalQuizResponseModel> }
>(
  "quizzes/upsertProvisionalQuizResponse",
  "PUT",
  (args) => `/provisional-quiz-responses/${args.id}`,
  {
    getBody: (args) => args.body,
    returnVoid: true,
  }
);

export const updateProvisionalQuizResponse = createApiCall<
  void,
  { id: string; body: Partial<ProvisionalQuizResponseModel> }
>(
  "quizzes/updateProvisionalQuizResponse",
  "PATCH",
  (args) => `/provisional-quiz-responses/${args.id}`,
  {
    getBody: (args) => args.body,
    returnVoid: true,
  }
);

export const quizzesSlice = createSlice({
  name: "quizzes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchQuizSpecs.fulfilled, (state, action) => {
      state.specs = action.payload.items;
    });
    builder.addCase(fetchAllQuizResponses.fulfilled, (state, action) => {
      state.responses = action.payload.items;
    });
    builder.addCase(
      fetchAllProvisionalQuizResponses.fulfilled,
      (state, action) => {
        state.provisionalResponses = action.payload.items;
      }
    );
    builder.addCase(fetchQuizResponses.fulfilled, (state, action) => {
      state.responses = action.payload.items;
    });
  },
});

export const selectQuizSpecs = (state: RootState) => state.quizzes.specs;

export const selectQuizSpec = (state: RootState, specId?: string) =>
  (state.quizzes.specs ?? []).find((s) => s.id === specId);

export const selectQuizResponse = (state: RootState, quizResponseId?: string) =>
  (state.quizzes.responses ?? []).find((r) => r.id === quizResponseId);

export const selectQuizResponses = (state: RootState, profileId?: string) =>
  (state.quizzes.responses ?? []).filter((r) => r.profileId === profileId);

export const selectProvisionalQuizResponses = (
  state: RootState,
  profileId?: string
) => state.quizzes.provisionalResponses ?? [];

export default quizzesSlice.reducer;
