import React, { useCallback, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import styled from "styled-components";
import tw from "twin.macro";
import { Header } from "components/headers";
import Footer from "components/footers/footer";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import { Quiz } from "components/quiz/Quiz";
import { useState } from "react";
import { QuizResponseModel } from "store/quizzes/schemas";
import { ScoreReport } from "components/quiz/ScoreReport";
import SignUpForm from "components/forms/SignUpForm";
import { useAppDispatch, useAppSelector } from "store/types";
import { createQuizResponse } from "store/profiles/slice";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { selectAuthToken } from "store/auth/slice";

const Container = tw.div`relative`;
const OneColumn = tw.div`max-w-screen-xl mx-auto py-4 md:py-8`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-8 md:py-8`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left self-start`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end self-start w-full`;
const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

type CohortTag = "Ureeka" | "Cosmodrome" | "Extenda" | "JTBD.online";

type CohortLandingPageProps = {
  tag: CohortTag;
  title: string;
  subtitle?: string;
  logo: `/assets/images/clients/${string}.${"png" | "jpg"}`;
  logoHeight: `${number}rem`;
  onComplete?: (info: { authToken: string; quizScore: number }) => void;
  orientation?: "horizontal" | "vertical";
};

export const CohortLandingPage = ({
  tag,
  title,
  subtitle,
  logo,
  logoHeight,
  onComplete,
  orientation = "vertical",
}: CohortLandingPageProps) => {
  const [quizResponse, setQuizResponse] = useState<
    QuizResponseModel | undefined
  >();
  const [quizScore, setQuizScore] = useState<number>(0);

  const dispatch = useAppDispatch();
  const authToken = useAppSelector(selectAuthToken);

  useAuthenticatedUser({ redirectToLogin: false, tag });

  const onQuizPersisted = useCallback(() => {
    // Allow the behavior to be overridden (but the default should be to go to the "My Score" page)
    if (authToken) {
      if (onComplete) {
        onComplete({ quizScore, authToken });
      } else {
        window.location.href = "/my-score";
      }
    }
  }, [onComplete, quizScore, authToken]);

  return (
    <AnimationRevealPage disabled>
      <Header></Header>
      <Container>
        {orientation === "horizontal" ? (
          <React.Fragment>
            <OneColumn>
              <div tw="text-center">
                <Heading>{title}</Heading>
              </div>
            </OneColumn>
            <div tw="flex flex-col lg:flex-row lg:items-start max-w-screen-xl mx-auto py-8 md:py-8">
              <div tw="relative w-full lg:w-auto flex mx-0 lg:mx-20 justify-center">
                <img src={logo} alt={tag} style={{ height: logoHeight }} />
              </div>
              <div tw="relative flex-1 flex flex-col self-center w-full">
                {subtitle && <Paragraph>{subtitle}</Paragraph>}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <OneColumn>
            <div tw="text-center">
              <Heading>{title}</Heading>
              {subtitle && <Paragraph>{subtitle}</Paragraph>}
            </div>
            <div tw="flex items-center justify-center my-8">
              <img src={logo} alt={tag} style={{ height: logoHeight }} />
            </div>
          </OneColumn>
        )}
        <TwoColumn>
          {quizResponse !== undefined ? (
            <React.Fragment>
              <LeftColumn>
                <ScoreReport
                  specId={quizResponse.specId}
                  answers={quizResponse.answers}
                  onSave={onQuizPersisted}
                />
              </LeftColumn>
              <RightColumn>
                <div tw="lg:ml-24">
                  <SignUpForm
                    onComplete={() => {
                      dispatch(createQuizResponse(quizResponse))
                        .unwrap()
                        .then(onQuizPersisted);
                    }}
                  />
                </div>
              </RightColumn>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <LeftColumn tw="text-left">
                <Heading>
                  <span tw="text-primary-500">Startup IQ</span> helps founders
                  understand where they are on the startup journey, and takes
                  them to the next step with confidence.
                </Heading>
                <Heading tw="mt-8">Start with this self-evaluation.</Heading>
              </LeftColumn>
              <RightColumn>
                <div tw="mx-4 md:mx-8 lg:mx-16">
                  <Quiz
                    track="startup"
                    onSave={onQuizPersisted}
                    onComplete={(q, s) => {
                      setQuizResponse(q);
                      setQuizScore(s);
                    }}
                  />
                </div>
              </RightColumn>
            </React.Fragment>
          )}
        </TwoColumn>
        <Footer></Footer>
      </Container>
    </AnimationRevealPage>
  );
};
