import Footer from "components/footers/footer";
import { Header } from "components/headers";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React, { useState } from "react";
import { useEffect } from "react";
import { ProductType } from "components/products/ProductType";

const OneColumn = tw.div`max-w-screen-xl mx-auto py-8 md:py-8`;
const Button = tw.button`inline-block bg-primary-500 text-gray-100 font-bold my-4 sm:my-2 rounded-full py-2 px-4 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`;
const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

type PageSettings = {
  heading: string;
  message: string;
  destination: string;
  button: string;
};

export default function QuestionsPage() {
  const [pageSettings, setPageSettings] = useState<PageSettings>({
    heading: "Startup IQ",
    message: "",
    destination: "/dashboard",
    button: "Back to dashboard",
  });

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    const overrides: Partial<PageSettings> = {};

    if (query.get("success")) {
      const type = query.get("type") as ProductType | null;

      if (type === "question") {
        overrides.button = "Take me to my question(s)";
        overrides.destination = "/my-questions";
      }

      overrides.heading = "Startup IQ Order Complete";
      overrides.message = "Order placed! Your account will be updated shortly.";
    } else if (query.get("canceled")) {
      overrides.heading = "Startup IQ Order Canceled";
      overrides.message = "Order canceled.";
    }

    setPageSettings((defaults) => ({
      ...defaults,
      ...overrides,
    }));
  }, []);

  return (
    <AnimationRevealPage disabled>
      <Header></Header>
      <div tw="relative">
        <OneColumn>
          <div tw="text-center">
            <Heading>{pageSettings.heading}</Heading>
          </div>

          <Paragraph>{pageSettings.message}</Paragraph>

          <Button
            onClick={() => (window.location.href = pageSettings.destination)}
          >
            {pageSettings.button}
          </Button>
        </OneColumn>
      </div>
      <Footer></Footer>
    </AnimationRevealPage>
  );
}
