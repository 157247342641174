import { combineReducers, AnyAction } from "@reduxjs/toolkit";
import admin from "./admin/slice";
import auth, { signOut } from "./auth/slice";
import products from "./products/slice";
import profiles from "./profiles/slice";
import questions from "./questions/slice";
import quizzes from "./quizzes/slice";
import startupSupporters from "./startupSupporters/slice";
import users from "./users/slice";
import storage from "redux-persist/lib/storage";

const appReducer = combineReducers({
  admin,
  auth,
  products,
  profiles,
  questions,
  quizzes,
  startupSupporters,
  users,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action?.type === signOut.type) {
    Object.keys(state).forEach((key) => {
      storage.removeItem(`persist:${key}`);
    });
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
