import { ProductType } from "components/products/ProductType";
import { API_URL } from "store/config";

export type StripeCallbackUrls = {
  successUrl: string;
  cancelUrl: string;
};

export const getDomain = () => {
  let port = "";
  if (window.location.port !== "80" && window.location.port !== "443") {
    port = `:${window.location.port}`;
  }

  return `${window.location.protocol}//${window.location.hostname}${port}`;
};

export const getStripeCallbackUrls = (
  type: ProductType
): StripeCallbackUrls => {
  const domain = getDomain();

  return {
    successUrl: `${domain}/checkout?success=true&type=${type}`,
    cancelUrl: `${domain}/checkout?canceled=true&type=${type}`,
  };
};

export const redirectToStripe = async (
  authToken: string,
  productId: string,
  successUrl: string,
  cancelUrl: string
) => {
  const url = `${API_URL}/stripe/checkout-sessions`;

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      productId,
      successUrl,
      cancelUrl,
    }),
  }).then(async (r) => {
    const json = await r.json();
    window.location.href = json.url;
  });
};
