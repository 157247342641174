import React, { useEffect, useState } from "react";
import { StartupSupporterModel } from "store/startupSupporters/schemas";
import { UserModel } from "store/users/schemas";
import { fetchStartupSupporterUsers } from "store/startupSupporters/slice";
import { useAppDispatch } from "store/types";
import { Table } from "components/table";

type StartupSupporterUsersProps = {
  startupSupporter?: StartupSupporterModel;
};

export const StartupSupporterUsers = ({
  startupSupporter,
}: StartupSupporterUsersProps) => {
  const dispatch = useAppDispatch();
  const [users, setUsers] = useState<UserModel[]>([]);

  useEffect(() => {
    if (startupSupporter) {
      dispatch(fetchStartupSupporterUsers(startupSupporter.id))
        .unwrap()
        .then((result) => setUsers(result.items));
    }
  }, []);

  if (!startupSupporter) {
    return <div>No startup supporter</div>;
  }

  return (
    <Table
      data={users}
      columns={[
        { name: "ID", field: "id" },
        { name: "Email Address", field: "emailAddress" },
        { name: "Name", field: "name" },
        { name: "Role", field: "role" },
      ]}
    />
  );
};
