import React, { useMemo } from "react";

import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";

import { getAnswerText } from "helpers/quiz";
import { upperFirst } from "helpers/text";
import { QuizResponseModel } from "store/quizzes/schemas";
import { selectQuizSpec } from "store/quizzes/slice";
import { useAppSelector } from "store/types";
import { Table } from "components/table";

type QuizResponseDetailsProps = {
  response: QuizResponseModel;
};
export const QuizResponseDetails = ({ response }: QuizResponseDetailsProps) => {
  const spec = useAppSelector((state) =>
    selectQuizSpec(state, response.specId)
  );

  const questionResponses = useMemo(
    () =>
      spec?.questions.map((questionSpec, i) => {
        const questionAnswers = response.answers[questionSpec.id] ?? [];

        const score =
          questionAnswers.length === 0
            ? ""
            : questionAnswers
                .map(
                  (a) =>
                    questionSpec.options.find((o) => o.id === a.id)?.score ?? 0
                )
                .reduce((a, b) => a + b, 0);

        return {
          question: `${questionSpec.title} ${questionSpec.description}`,
          dimension: upperFirst(questionSpec.dimension),
          answer: getAnswerText(questionAnswers, questionSpec),
          score,
        };
      }) ?? [],
    [response.answers, spec?.questions]
  );

  return (
    <div>
      <strong>Response {response.id}</strong>
      <Table
        data={questionResponses}
        columns={[
          { name: "Question", field: "question" },
          { name: "Dimension", field: "dimension" },
          { name: "Answer", field: "answer" },
          { name: "Score", field: "score" },
        ]}
      />
    </div>
  );
};
