import styled from "styled-components";
import tw from "twin.macro";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";

const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const Button = tw.button`inline-block bg-primary-500 text-gray-100 font-bold my-4 sm:my-2 rounded-full py-2 sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`;

type Stage = {
  title: string;
  items: string[];
};

const stages: Stage[] = [
  {
    title: "Pre-Seed",
    items: [
      "You've been toying with a new business idea.",
      "You have a domain name.",
      "You freelanced for a while, and you have skills to bring to the table.",
    ],
  },
  {
    title: "Seed",
    items: [
      "You have a co-founder in mind, or still looking for one.",
      "You know your market and your customer.",
      "You are incorporated.",
      "You sold to your first customer already.",
    ],
  },
  {
    title: "Early",
    items: [
      "You can solve a problem.",
      "Revenue is coming in.",
      "You need healthier profits or funding to get over a hump.",
      "You might feel stuck in the same place.",
    ],
  },
  {
    title: "Growth",
    items: [
      "You are ready to scale.",
      "We are too small to keep up with you.",
      "We say goodbye, and wish good luck on your way to making a difference.",
    ],
  },
];

type WhereAreYouNowProps = {
  showDetails?: boolean;
  onClick?: () => void;
};
export const WhereAreYouNow = ({
  showDetails = true,
  onClick,
}: WhereAreYouNowProps) => {
  return (
    <div>
      <Heading tw="text-primary-500 text-center">Where Are You Now?</Heading>
      <Paragraph tw="text-center">
        You can figure this out by recognizing a few of the signals below or by
        calculating your
      </Paragraph>
      <div tw="text-center">
        <Button
          onClick={() =>
            onClick
              ? onClick()
              : window.scrollTo({ top: 0, behavior: "smooth" })
          }
        >
          Startup IQ Score
        </Button>
      </div>
      {/* Title, only visible on desktop */}
      <div tw="invisible md:visible flex w-full md:mt-12 md:mb-8">
        {stages.map((s, i) => (
          <div key={i} tw="w-1/4 text-center font-bold uppercase">
            {s.title}
          </div>
        ))}
      </div>

      {/* Dots and lines, only visible on desktop */}
      <div tw="invisible md:visible">
        <div tw="flex relative top-[8px]">
          {stages.map((_, i) => (
            <div key={i} tw="flex justify-center w-1/4">
              <div tw="h-3 w-3 rounded-full bg-primary-500"></div>
            </div>
          ))}
        </div>
        <div tw="w-full h-1 bg-primary-500"></div>
      </div>

      <div tw="flex flex-wrap md:mt-8">
        {stages.map((s, i) => (
          <div key={i} tw="w-full md:w-1/4 text-center px-8">
            {/* Mobile-only title */}
            <div tw="md:invisible text-center font-bold uppercase mt-4 md:mt-0">
              {s.title}
            </div>

            {showDetails
              ? s.items.map((item, j) => (
                  <div key={j} tw="py-2">
                    {item}
                  </div>
                ))
              : null}
          </div>
        ))}
      </div>
    </div>
  );
};
