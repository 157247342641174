import tw from "twin.macro";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React, { useState, useMemo } from "react";

const TableInner = tw.table`table-auto w-full`;
const ColumnHeader = tw.th`pr-4`;
const Row = tw.tr`border-b-2`;
const Cell = tw.td`pr-4 pb-4`;

type ColumnDefinition<T> = {
  name: string;
  field?: keyof T;
  sortBy?: (item: T) => string;
  render?: (item: T) => React.ReactNode;
};

type SortOrder = "asc" | "desc" | "none";

const getNextSortOrder = (current: SortOrder | undefined): SortOrder => {
  switch (current) {
    case "asc":
      return "desc";
    case "desc":
      return "none";
    default:
      return "asc";
  }
};
const getSortIcon = (current: SortOrder | undefined): string => {
  switch (current) {
    case "asc":
      return "▲";
    case "desc":
      return "▼";
    default:
      return "";
  }
};

type TableProps<T> = {
  data: T[];
  columns: ColumnDefinition<T>[];
};
export const Table = <T extends object>({ data, columns }: TableProps<T>) => {
  const [sortOrders, setSortOrders] = useState<Record<number, SortOrder>>({});

  const sortedData = useMemo(() => {
    const compareFn = (a: T, b: T) => {
      for (const [index, sortOrder] of Object.entries(sortOrders)) {
        if (sortOrder !== "none") {
          const sortFn = columns[parseInt(index, 10)].sortBy!;
          const valueA = sortFn(a);
          const valueB = sortFn(b);
          const sortValue = valueA.localeCompare(valueB);
          if (sortValue !== 0) {
            return sortOrder === "desc" ? -sortValue : sortValue;
          }
        }
      }

      return 0;
    };

    return data.map((d, i) => ({ ...d, __key: i })).sort(compareFn);
  }, [data, sortOrders]);

  const onSortClick = (i: number) => () => {
    setSortOrders((value) => ({ [i]: getNextSortOrder(value[i]) }));
  };

  return (
    <TableInner tw="table-auto w-full">
      <thead>
        <Row>
          {columns.map((c, i) => (
            <ColumnHeader
              onClick={c.sortBy ? onSortClick(i) : undefined}
              key={i}
            >
              {c.name}
              {getSortIcon(sortOrders[i])}
            </ColumnHeader>
          ))}
        </Row>
      </thead>
      <tbody>
        {sortedData.map((item, i) => (
          <Row key={item.__key}>
            {columns.map((c, j) => {
              const renderValue = () => {
                if (c.render) {
                  return c.render(item);
                } else if (c.field) {
                  return item[c.field];
                }
                return null;
              };

              return <Cell key={j}>{renderValue()}</Cell>;
            })}
          </Row>
        ))}
      </tbody>
    </TableInner>
  );
};
