import tw from "twin.macro";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import { Slider } from "../slider/Slider";
import { useAppSelector } from "store/types";
import { selectCurrentProfile } from "store/profiles/slice";
import { QuestionDimension } from "store/quizzes/schemas";

type DimensionProps = {
  label: string;
  min: number;
  max: number;
  value: number;
};
const Dimension = ({ label, min, max, value }: DimensionProps) => {
  return (
    <div tw="flex">
      <div tw="w-1/3 sm:w-1/4 font-bold text-primary-500 uppercase">
        {label}
      </div>
      <div tw="w-2/3 sm:w-3/4">
        <Slider min={min} max={max} value={value} emphasizeValue />
      </div>
    </div>
  );
};

export const Dimensions = () => {
  const profile = useAppSelector(selectCurrentProfile);

  const dimensions: QuestionDimension[] = [
    "purpose",
    "motivation",
    "team",
    "knowledge",
    "finance",
  ];

  return (
    <div tw="rounded-md border border-primary-500 p-10">
      {dimensions.map((d) => {
        const scoreDimension = (profile?.scoreDimensions ?? []).find(
          (s) => s.dimension === d
        );

        return (
          <Dimension
            key={d}
            label={d}
            min={scoreDimension?.min ?? 0}
            max={scoreDimension?.max ?? 0}
            value={scoreDimension?.value ?? 0}
          />
        );
      })}
    </div>
  );
};
