import styled from "styled-components";
import tw from "twin.macro";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React from "react";
import { HomepageTestimonials } from "./HomepageTestimonials";
import { SimplePrimaryBackground } from "components/testimonials/SimplePrimaryBackground";

const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const TwoColumn = tw.div`flex flex-col lg:flex-row max-w-screen-xl mx-auto py-4 `;
const LeftColumn = tw.div`w-full lg:w-1/2 lg:px-2 py-4`;
const RightColumn = tw.div`w-full lg:w-1/2 lg:px-2 py-4`;
// const LeftColumn = tw.div`relative w-full lg:w-1/2 text-center max-w-lg mx-auto lg:max-w-none lg:text-left self-start bg-red-500`;
// const RightColumn = tw.div`relative mt-8 lg:mt-16 flex-1 flex flex-col self-start w-full`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const Button = tw.button`inline-block bg-primary-500 text-gray-100 font-bold my-4 sm:my-2 rounded-full py-2 px-4 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`;

const List = tw.ul`list-disc pl-12`;
const Item = tw.li`pt-2`;

export const StatsContent = () => (
  <React.Fragment>
    <Paragraph>
      As a first time startup founder, the odds are not in your favor.{" "}
      <strong>About 90% of the startups fail:</strong>
    </Paragraph>
    <List>
      <Item>10%-20% of the startups fail by the end of their 1st year.</Item>
      <Item>30% fail by the end of the 2nd year.</Item>
      <Item>50% fail by the end of the 5th year.</Item>
      <Item>70% fail by the end of the 10th year.</Item>
    </List>
    <Paragraph>
      Only 40% of the startups turn a profit. 30% break even, and 30%
      continuously lose money.
    </Paragraph>
    <Paragraph>The top four reasons for startup failure are:</Paragraph>
    <List>
      <Item>misreading market demand (42%).</Item>
      <Item>running out of funding and personal money (29%).</Item>
      <Item>a weak founding team (23%) and</Item>
      <Item>being beat by competition (19%)</Item>
    </List>
    <Paragraph>
      We have the framework to help you beat these odds, and train you on how to
      avoid each failure point individually.
    </Paragraph>
  </React.Fragment>
);

export const HomepageStatistics = () => {
  return (
    <TwoColumn>
      <LeftColumn>
        <Heading tw="text-primary-500 text-center">Is This You?</Heading>
        <Paragraph>
          There are about 32 million entrepreneurs in the U.S. And 69% of them
          started their business at home. Out of the Covid unemployment
          rollercoaster, the Big Resignation, and the Big Tech layoff, the
          number of new business applications just keeps going up. If you are
          coming from the tech industry but you are a first time startup
          founder, we would love to work with you.
        </Paragraph>
        <img
          src="/assets/images/home/business_applications.png"
          alt="U.S. total annual business applications"
        />
      </LeftColumn>
      <RightColumn>
        <Heading tw="text-primary-500 text-center">It's Not Easy</Heading>
        <StatsContent />
        <Paragraph>
          Are you ready to{" "}
          <Button
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            start
          </Button>
          ?
        </Paragraph>
      </RightColumn>
    </TwoColumn>
  );
};

export const HomepageStatisticsWithBackground = () => {
  return (
    <SimplePrimaryBackground heading="It's Not Easy" description="">
      <StatsContent />
    </SimplePrimaryBackground>
  );
};
