import Footer from "components/footers/footer";
import { Header } from "components/headers";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import tw from "twin.macro";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React from "react";
import { ProductType } from "components/products/ProductType";

const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-8 md:py-8`;
const LeftColumn = tw.div`relative lg:w-1/2 text-center max-w-lg sm:mr-4 lg:mr-12 mx-auto lg:max-w-none lg:text-left self-start`;
const RightColumn = tw.div`relative lg:w-1/2 mt-12 lg:mt-0 sm:ml-4 lg:ml-12 flex-1 flex flex-col justify-center self-start w-full`;

type SharedLayoutProps = {
  children: [React.ReactNode, React.ReactNode];
};

export const SharedLayout = ({ children }: SharedLayoutProps) => {
  return (
    <AnimationRevealPage disabled>
      <Header></Header>
      <div tw="relative">
        <TwoColumn tw="md:mt-16">
          <LeftColumn>{children[0]}</LeftColumn>
          <RightColumn>{children[1]}</RightColumn>
        </TwoColumn>
      </div>
      <Footer></Footer>
    </AnimationRevealPage>
  );
};
