import Footer from "components/footers/footer";
import { Header } from "components/headers";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React, { useMemo } from "react";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/types";
import {
  fetchCurrentProfileQuestions,
  selectCurrentProfileQuestions,
} from "store/questions/slice";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { QuestionStatus } from "store/questions/schemas";
import { AskQuestion } from "components/questions/AskQuestion";
import { QuestionDisplay } from "components/questions/QuestionDisplay";
import { PurchaseProduct } from "components/products/PurchaseProduct";
import {
  FIVE_QUESTIONS,
  ONE_QUESTION,
  THREE_QUESTIONS,
} from "constants/products";

const OneColumn = tw.div`max-w-screen-xl mx-auto py-8 md:py-8`;
const TextArea = tw.textarea`w-full h-72 sm:h-48 resize-none border rounded border-primary-600 focus:outline-none transition duration-300 hocus:border-primary-500`;
const Button = tw.button`inline-block bg-primary-500 text-gray-100 font-bold my-4 sm:my-2 rounded-full py-2 sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`;
const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

export const QuestionsPage = () => {
  const dispatch = useAppDispatch();

  const { user } = useAuthenticatedUser();

  useEffect(() => {
    if (user) {
      dispatch(fetchCurrentProfileQuestions());
    }
  }, [user]);

  const questions = useAppSelector(selectCurrentProfileQuestions);

  const { draftQuestions, submittedQuestions } = useMemo(() => {
    return {
      draftQuestions: questions.filter(
        (p) => p.status === QuestionStatus.Draft
      ),
      submittedQuestions: questions
        .filter((p) => p.status === QuestionStatus.Submitted)
        .sort((a, b) => a.created.localeCompare(b.created))
        .reverse(),
    };
  }, [questions]);

  return (
    <AnimationRevealPage disabled>
      <Header></Header>
      <div tw="relative">
        <OneColumn>
          <div tw="text-center">
            <Heading>Get your questions answered</Heading>
          </div>

          {draftQuestions.length === 0 ? (
            <React.Fragment>
              <Paragraph>
                You don't have any questions available. Select the package that
                fits you best.
              </Paragraph>
              <div tw="flex">
                <PurchaseProduct
                  type="question"
                  labelFn={({ price }) => `1 Question - ${price}`}
                  productId={ONE_QUESTION}
                />
                <PurchaseProduct
                  type="question"
                  labelFn={({ price }) => `3 Questions - ${price}`}
                  productId={THREE_QUESTIONS}
                />
                <PurchaseProduct
                  type="question"
                  labelFn={({ price }) => `5 Questions - ${price}`}
                  productId={FIVE_QUESTIONS}
                />
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Paragraph>
                You have {draftQuestions.length}{" "}
                {draftQuestions.length === 1 ? "question" : "questions"}{" "}
                available. Type {draftQuestions.length === 1 ? "it" : "one"} in
                the form below.
              </Paragraph>
              <AskQuestion questionId={draftQuestions[0].id} />
            </React.Fragment>
          )}

          <div tw="text-center mt-10">
            <Heading>Your questions</Heading>
          </div>
          {submittedQuestions.map((q, i) => (
            <div key={i} tw="my-4">
              <QuestionDisplay
                body={q.body}
                created={q.created}
                responses={q.responses}
              />
            </div>
          ))}
          {submittedQuestions.length === 0 ? (
            <Paragraph>You haven't asked any questions yet.</Paragraph>
          ) : null}
        </OneColumn>
      </div>
      <Footer></Footer>
    </AnimationRevealPage>
  );
};
