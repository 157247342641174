import React, { useMemo } from "react";

import tw from "twin.macro";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";

type SliderProps = {
  min: number;
  max: number;
  value: number;
  additionalLabels?: { value: number; label: string }[];
  emphasizeValue?: boolean;
  showLinecaps?: boolean;
};

type ChipProps = {
  min: number;
  max: number;
  value: number;
  label?: string;
};

const getPercent = (min: number, max: number, value: number) => {
  const range = max - min;
  return Math.floor(((value - min) / range) * 100);
};

export const Chip = ({ min, max, value, label }: ChipProps) => {
  const percent = useMemo(() => {
    return getPercent(min, max, value);
  }, [min, max, value]);

  return (
    <div
      style={{
        position: "absolute",
        left: percent < 50 ? `${percent}%` : "",
        right: percent >= 50 ? `${100 - percent}%` : "",
      }}
    >
      {/* Optional label above colored dot */}
      <div
        tw="absolute text-primary-500 font-bold uppercase"
        style={{ left: "50%", transform: "translate(-50%, -28px)" }}
      >
        <div tw="relative">{label}</div>
      </div>

      {/* Colored dot itself */}
      <div tw="relative w-[14px] h-[14px] rounded-full bg-primary-500"></div>

      {/* Value label below colored dot */}
      <div
        tw="absolute text-primary-500 font-bold"
        style={{ left: "50%", transform: "translate(-50%, 4px)" }}
      >
        <div tw="relative">{value}</div>
      </div>
    </div>
  );
};

export const Slider = ({
  min,
  max,
  value,
  additionalLabels,
  emphasizeValue,
  showLinecaps,
}: SliderProps) => {
  return (
    <div tw="relative h-[4rem]">
      {/* Full-width gray line */}
      <div tw="absolute top-[7px] w-full h-px bg-gray-500"></div>

      {/* Bold, colored line that goes to the colored dot */}
      {emphasizeValue && (
        <div
          tw="absolute top-[5px] h-1 bg-primary-500"
          style={{ width: `${getPercent(min, max, value)}%` }}
        ></div>
      )}

      {/* Gray dots at start and end of line */}
      {showLinecaps && (
        <React.Fragment>
          <div tw="absolute w-[14px] h-[14px] rounded-full bg-gray-500"></div>
          <div tw="absolute w-[14px] h-[14px] rounded-full bg-gray-500 right-0"></div>
        </React.Fragment>
      )}
      {[{ value, label: undefined }, ...(additionalLabels ?? [])].map(
        (item, i) => (
          <Chip
            key={i}
            min={min}
            max={max}
            value={item.value}
            label={item.label}
          />
        )
      )}
    </div>
  );
};
