import tw from "twin.macro";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import { Slider } from "../slider/Slider";
import { useAppSelector } from "store/types";
import { selectCurrentProfile } from "store/profiles/slice";
import { Heading } from "components/misc/Headings";
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const FirstWord = tw.span`text-primary-500 font-bold`;

export const Stages = () => {
  const profile = useAppSelector(selectCurrentProfile);

  return (
    <div>
      <div tw="text-center">
        <Heading>The stages of your startup</Heading>
      </div>
      <div tw="flex mt-16">
        <div tw="mr-12 font-bold text-primary-500 uppercase">Score</div>
        <div tw="w-full">
          <Slider
            min={-4}
            max={150}
            value={profile?.score ?? 0}
            emphasizeValue
            additionalLabels={[
              { value: 48, label: "Seed" },
              { value: 96, label: "Early" },
              { value: 144, label: "Growth" },
            ]}
          />
        </div>
      </div>
      <Paragraph>
        <FirstWord>SEED:</FirstWord> You are putting together the basics of your
        business, by building a healthy foundation. Without it, it&apos;s hard
        to go far.
      </Paragraph>
      <Paragraph>
        <FirstWord>EARLY:</FirstWord> You&apos;ve been bootstrapping, and you
        are doing most of the things right. As a result you see an increase in
        customers, revenue and profit. It&apos;s time to think what can you take
        your business to scale.
      </Paragraph>
      <Paragraph>
        <FirstWord>GROWTH:</FirstWord> Here you got the operations down. Team,
        process, tech, product or service. Anything more requires a significant
        jump in operating revenue or external funding.
      </Paragraph>
    </div>
  );
};
