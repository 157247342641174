import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useAppDispatch } from "store/types";
import { fetchCurrentProfile } from "store/profiles/slice";
import { authUser, setToken } from "store/auth/slice";
import { LINKEDIN_PROFILE } from "constants/localStorage";

type useAuthenticatedUserParams = { redirectToLogin: boolean; tag?: string };

export type AuthenticatedUser = {
  photoURL?: string;
  displayName: string;
  email: string;
};

export const useAuthenticatedUser = (
  { redirectToLogin, tag }: useAuthenticatedUserParams = {
    redirectToLogin: true,
  }
): { user: AuthenticatedUser | null; loading: boolean } => {
  const dispatch = useAppDispatch();
  const [user, setUser] = useState<AuthenticatedUser | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    Promise.any([
      new Promise<{ auth_token: string; user: AuthenticatedUser }>(
        (resolve, reject) => {
          const linkedInProfileJson =
            window.localStorage.getItem(LINKEDIN_PROFILE);
          if (linkedInProfileJson) {
            const linkedInProfile = JSON.parse(linkedInProfileJson);
            console.log({ linkedInProfile });
            resolve({
              auth_token: linkedInProfile.auth_token,
              user: {
                photoURL: linkedInProfile.imageUrl,
                displayName: linkedInProfile.name,
                email: linkedInProfile.email,
              },
            });
          } else {
            reject("LinkedIn not available");
          }
        }
      ),
      new Promise<{ auth_token: string; user: AuthenticatedUser }>(
        (resolve, reject) => {
          const firebaseAuth = getAuth();
          onAuthStateChanged(firebaseAuth, async (user) => {
            if (user) {
              console.log(user);

              const token = await user.getIdToken();

              resolve({
                auth_token: token,
                user: {
                  photoURL: user.photoURL ?? undefined,
                  displayName: user.displayName ?? "",
                  email: user.email ?? "",
                },
              });
            } else {
              reject("Firebase not available");
            }
          });
        }
      ),
    ])
      .then(async (result) => {
        // TODO: Make this process more streamlined
        dispatch(setToken(result.auth_token));
        await dispatch(authUser({ tag }));
        setUser({
          photoURL: result.user.photoURL,
          displayName: result.user.displayName,
          email: result.user.email,
        });
        dispatch(fetchCurrentProfile());
      })
      .catch((x) => {
        if (redirectToLogin) {
          window.location.href = "/auth";
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { user, loading };
};
