import React from "react";
import { Track } from "store/quizzes/schemas";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";

import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`text-base xl:text-lg text-center font-semibold`;
const QuizTrackButton = tw(
  PrimaryButtonBase
)`inline-block mt-8 mb-8 p-8 w-full`;

type QuizTrackSelectorProps = {
  title?: string;
  compact: boolean;
  onChange: (value: Track) => void;
};

export const QuizTrackSelector = ({
  title,
  compact,
  onChange,
}: QuizTrackSelectorProps) => {
  return (
    <React.Fragment>
      {title && <Heading tw="text-center">{title}</Heading>}
      {!compact && (
        <Paragraph>
          Before you get the first serious investment, you need to have certain
          things ready. Not just in your pitch deck, but in reality. A bootcamp,
          an incubator, or an accelerator can hone your skills. Let us get you
          ready for each step, and match you with the right partner.
        </Paragraph>
      )}

      <QuizTrackButton onClick={() => onChange("startup")}>
        I have a business
      </QuizTrackButton>

      <QuizTrackButton onClick={() => onChange("entrepreneur")}>
        I want to start a business
      </QuizTrackButton>
    </React.Fragment>
  );
};
