import AnimationRevealPage from "helpers/AnimationRevealPage";
import styled from "styled-components";
import tw from "twin.macro";
import { Header } from "components/headers";
import Footer from "components/footers/footer";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import { Quiz } from "components/quiz/Quiz";
import { WhereAreYouNow } from "components/home/WhereAreYouNow";
import { WhatCanYouDo } from "components/home/WhatCanYouDo";
import { ProductGallery } from "components/home/ProductGallery";
import { HomepageStatistics } from "components/home/HomepageStatistics";
import { HowWeHelp } from "components/home/HowWeHelp";
import { OurGoals } from "components/home/OurGoals";
import { HomepageTestimonials } from "components/home/HomepageTestimonials";

const Container = tw.div`relative`;
const OneColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-10 md:py-12`;

const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-4 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left self-start`;
const RightColumn = tw.div`relative mt-8 lg:mt-16 flex-1 flex flex-col justify-center self-start w-full`;
const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input[type=text] {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  input[type="submit"] {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hocus:bg-primary-700 hocus:text-gray-200 transition duration-300`}
  }
`;

export default function HomePage() {
  return (
    <AnimationRevealPage disabled>
      <Header></Header>
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading tw="mt-16">
              <span tw="text-primary-500">Startup IQ</span> helps first time
              startup founders build toward a healthy business model.
              <br />
              <br />
              Yes, funding is important, but so are others: your team, your
              customer knowledge and the market demand, as well as your
              competition.
              <br />
              <br />
              We help you work on them.
            </Heading>
          </LeftColumn>
          <RightColumn>
            <div tw="mx-4 md:mx-8 lg:mx-16">
              <Quiz
                title="Start with your Startup IQ Score."
                compact
                onSave={() => {
                  window.location.href = "/my-score";
                }}
              />
            </div>
          </RightColumn>
        </TwoColumn>

        <HomepageStatistics />

        <OurGoals />

        <OneColumn>
          <HowWeHelp />
        </OneColumn>

        <OneColumn>
          <WhereAreYouNow />
        </OneColumn>

        <OneColumn tw="pb-0">
          <WhatCanYouDo page="default" />
        </OneColumn>
        <OneColumn tw="pt-0 text-center">
          <Paragraph>
            This is not about checking boxes. This is about keeping your work
            organized and ready to go when you need it. Creating anything on the
            list above takes time, and without guidance is even harder. But most
            importantly, it helps you be a better founder where and when it
            matters.
          </Paragraph>
        </OneColumn>

        <OneColumn>
          <ProductGallery heading="Start with a Plan that Works for You" />
        </OneColumn>

        <HomepageTestimonials />

        <OneColumn>
          <div tw="text-center w-full flex flex-col items-center">
            <Heading tw="text-primary-500 text-center">
              Monthly Startup Wrap
            </Heading>
            <Paragraph>
              We'll send you a selection of good and bad tweets, so you can
              focus on building your business.
            </Paragraph>
            <Actions>
              <form
                action="https://www.getrevue.co/profile/StartupIQ/add_subscriber"
                method="post"
                id="revue-form"
                name="revue-form"
                target="_blank"
              >
                <input
                  type="text"
                  placeholder="Your E-mail Address"
                  name="member[email]"
                  id="member_email"
                />
                <input type="submit" value="Sign up" />
              </form>
            </Actions>
          </div>
        </OneColumn>

        <Footer></Footer>
      </Container>
    </AnimationRevealPage>
  );
}
