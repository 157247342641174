import React, { useState, useMemo, useEffect } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";

import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { QuestionAnswers } from "./types";
import {
  QuizResponseModel,
  QuizSpecificationModel,
  Track,
} from "store/quizzes/schemas";
import ObjectId from "bson-objectid";
import { useAppDispatch, useAppSelector } from "store/types";
import {
  fetchQuizSpecs,
  selectQuizSpecs,
  upsertProvisionalQuizResponse,
  updateProvisionalQuizResponse,
} from "store/quizzes/slice";
import { ScoreReport } from "./ScoreReport";
import { selectIsAuthenticated } from "store/auth/slice";
import {
  createQuizResponse,
  fetchCurrentProfile,
  selectCurrentProfile,
} from "store/profiles/slice";
import { QuizContext } from "./QuizContext";
import { QuizQuestion } from "./QuizQuestion";
import { QuizTrackSelector } from "./QuizTrackSelector";
import { ButtonSection } from "./ButtonSection";
import { getCurrentScore } from "helpers/quiz";

type QuizProps = {
  title?: string;
  compact?: boolean;
  onSave?: () => void;
  onComplete?: (response: QuizResponseModel, score: number) => void;
  track?: Track;
  specId?: string;
  allowBack?: boolean;
  allowSkip?: boolean;
  temporary?: boolean;
};

export const Quiz = ({
  title,
  compact,
  onSave,
  track: defaultTrack,
  specId: defaultSpecId,
  onComplete,
  allowBack,
  allowSkip,
  temporary,
}: QuizProps) => {
  const dispatch = useAppDispatch();

  const provisionalResponseId = useMemo(() => new ObjectId().toHexString(), []);

  const [questionIndex, setQuestionIndex] = useState(0);
  const quizSpecs = useAppSelector(selectQuizSpecs);

  const [quizSpec, setQuizSpec] = useState<
    QuizSpecificationModel | undefined
  >();

  const questions = useMemo(() => {
    return quizSpec?.questions ?? [];
  }, [quizSpec]);

  const quizStarted = questions.length > 0;
  const quizComplete = questionIndex === questions.length;

  const profile = useAppSelector(selectCurrentProfile);

  const [answers, setAnswers] = useState<Partial<QuestionAnswers>>({});

  useEffect(() => {
    dispatch(fetchQuizSpecs())
      .unwrap()
      .then((specs) => {
        if (defaultTrack) {
          setQuizSpec(specs.items.find((spec) => spec.track === defaultTrack));
        } else if (defaultSpecId) {
          setQuizSpec(specs.items.find((spec) => spec.id === defaultSpecId));
        }
      });
  }, []);

  const hasAnswer = useMemo(() => {
    if (quizComplete) {
      return true;
    }

    const question = questions[questionIndex];
    return (answers[question.id]?.length ?? 0) > 0;
  }, [answers, questions, questionIndex, quizComplete]);

  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  useEffect(() => {
    if (temporary) {
      return;
    }

    if (quizStarted && quizComplete && isAuthenticated) {
      dispatch(createQuizResponse({ specId: quizSpec?.id, answers }))
        .unwrap()
        .then(() =>
          dispatch(fetchCurrentProfile())
            .unwrap()
            .then((p) =>
              // Associate the provisional response with the profile
              dispatch(
                updateProvisionalQuizResponse({
                  id: provisionalResponseId,
                  body: { profileId: p.id },
                })
              )
            )
        );
    }
  }, [quizStarted, quizComplete, isAuthenticated]);

  useEffect(() => {
    if (quizStarted && quizComplete && onComplete) {
      const score = getCurrentScore(answers, quizSpec?.questions ?? []);

      onComplete({ specId: quizSpec?.id ?? "", answers }, score);
    }
  }, [quizComplete]);

  useEffect(() => {
    if (temporary) {
      return;
    }

    if (questionIndex > 0) {
      dispatch(
        upsertProvisionalQuizResponse({
          id: provisionalResponseId,
          body: { specId: quizSpec?.id ?? "", answers, profileId: profile?.id },
        })
      );
    }
  }, [questionIndex]);

  const showTrackSelector = !quizStarted && !defaultTrack && !defaultSpecId;
  const showQuiz = quizStarted && !quizComplete;
  const showScoreReport = quizStarted && quizComplete && !temporary;

  return (
    <div>
      {showTrackSelector && (
        <QuizTrackSelector
          title={title}
          compact={compact ?? false}
          onChange={(track) =>
            setQuizSpec(quizSpecs.find((spec) => spec.track === track))
          }
        />
      )}

      {showQuiz && (
        <React.Fragment>
          <QuizContext.Provider
            value={{
              onChange: (updatedAnswer) =>
                setAnswers({ ...answers, ...updatedAnswer }),
              answers,
            }}
          >
            <QuizQuestion question={questions[questionIndex]} />
          </QuizContext.Provider>

          <ButtonSection
            backAllowed={allowBack}
            skipAllowed={allowSkip}
            nextEnabled={hasAnswer}
            backEnabled={questionIndex > 0}
            onBack={() => setQuestionIndex(questionIndex - 1)}
            onNext={() => setQuestionIndex(questionIndex + 1)}
            onSkip={() => setQuestionIndex(questionIndex + 1)}
          />
        </React.Fragment>
      )}

      {showScoreReport && (
        <ScoreReport
          specId={quizSpec?.id ?? ""}
          answers={answers}
          onSave={onSave}
        />
      )}
    </div>
  );
};
