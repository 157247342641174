import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/configureStore";
import { QuestionModel, QuestionResponse, QuestionStatus } from "./schemas";
import { createApiCall } from "store/fetch";
import { CollectionResultModel } from "store/types";

const initialState: { all: QuestionModel[] } = { all: [] };

export const fetchCurrentProfileQuestions = createApiCall<
  CollectionResultModel<QuestionModel>
>(
  "questions/fetchCurrentProfileQuestions",
  "GET",
  () => "/profiles/current/questions"
);

export const updateQuestion = createApiCall<
  QuestionModel,
  { questionId: string; body: Partial<QuestionModel> }
>(
  "questions/updateQuestion",
  "PATCH",
  (args) => `/questions/${args.questionId}`,
  {
    getBody: (args) => args.body,
  }
);

export const submitQuestion = createApiCall<void, string>(
  "questions/submitQuestion",
  "POST",
  (id) => `/questions/${id}/actions?action=submit`
);

export const createCurrentProfileQuestion = createApiCall<
  QuestionModel,
  Partial<QuestionModel>
>(
  "questions/createCurrentProfileQuestion",
  "POST",
  () => "/profiles/current/questions",
  {
    getBody: (body) => body,
  }
);

export const createQuestionResponse = createApiCall<
  QuestionResponse,
  { questionId: string; body: string }
>(
  "questions/createQuestionResponse",
  "POST",
  (args) => `/questions/${args.questionId}/responses`,
  {
    getBody: (args) => ({ body: args.body }),
  }
);

export const profileQuestionsSlice = createSlice({
  name: "profileQuestions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentProfileQuestions.fulfilled, (state, action) => {
      state.all = action.payload.items;
    });
    builder.addCase(updateQuestion.fulfilled, (state, action) => {
      state.all = state.all.map((q) =>
        q.id === action.payload.id ? action.payload : q
      );
    });
    builder.addCase(createCurrentProfileQuestion.fulfilled, (state, action) => {
      state.all.push(action.payload);
    });
    builder.addCase(submitQuestion.fulfilled, (state, action) => {
      const question = state.all.find((q) => q.id === action.meta.arg);
      if (question) {
        question.status = QuestionStatus.Submitted;
      }
    });
    builder.addCase(createQuestionResponse.fulfilled, (state, action) => {
      const question = state.all.find(
        (q) => q.id === action.meta.arg.questionId
      );
      if (question) {
        question.responses.push(action.payload);
      }
    });
  },
});

export const selectCurrentProfileQuestions = (state: RootState) =>
  state.questions.all;

export default profileQuestionsSlice.reducer;
