import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

import { CohortLandingPage } from "./CohortLandingPage";

import { getDomain, redirectToStripe } from "helpers/Stripe";
import { COSMODROME_APPLICATION, JTBD_APPLICATION } from "constants/products";

export const UreekaLandingPage = () => {
  return (
    <CohortLandingPage
      tag="Ureeka"
      title="Welcome Ureeka Founders!"
      logo="/assets/images/clients/ureeka.png"
      logoHeight="3rem"
    />
  );
};

export const CosmodromeLandingPage = () => {
  return (
    <CohortLandingPage
      tag="Cosmodrome"
      title="Welcome Cosmodrome Founders!"
      logo="/assets/images/clients/cosmodrome2.jpg"
      logoHeight="12rem"
      onComplete={({ authToken, quizScore }) => {
        const domain = getDomain();

        // TODO: Use different URLs here
        const successUrl =
          quizScore > 48
            ? `${domain}/checkout?success=true`
            : `${domain}/checkout?success=true`;

        const cancelUrl =
          quizScore > 48
            ? `${domain}/checkout?success=false`
            : `${domain}/checkout?success=false`;

        redirectToStripe(
          authToken,
          COSMODROME_APPLICATION,
          successUrl,
          cancelUrl
        );
      }}
    />
  );
};

export const ExtendaLandingPage = () => {
  return (
    <CohortLandingPage
      tag="Extenda"
      title="Welcome Extenda Founders!"
      logo="/assets/images/clients/extenda.png"
      logoHeight="10rem"
    />
  );
};

export const JtbdOnlineLandingPage = () => {
  return (
    <CohortLandingPage
      tag="JTBD.online"
      title="Welcome JTBD.online Founders!"
      logo="/assets/images/clients/jtbd.jpg"
      logoHeight="12rem"
      subtitle="Please fill out this evaluation form so we can hone in on your key needs as a startup organization. Powered by our partner, Startup-IQ"
      orientation="horizontal"
    />
  );
};
