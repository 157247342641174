import "tailwindcss/dist/base.css";
import "./styles/globalStyles.css";
import React, { useContext, useEffect } from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";

export default function App() {
  return (
    <Router>
      <Routes />
    </Router>
  );
}
