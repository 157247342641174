import styled from "styled-components";
import tw from "twin.macro";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";

const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const BaseButton = tw.button`w-40 inline-block font-bold mx-2 rounded-full py-2 leading-none focus:outline-none transition duration-300`;

const Button = styled(BaseButton)((props: { disabled: boolean }) => [
  props.disabled
    ? tw`bg-gray-500 text-gray-100 cursor-default`
    : tw`bg-primary-500 text-gray-100 hover:bg-primary-900`,
]);

type Item = {
  prefix: string;
  label: string;
  postfix: string;
  disabled: boolean;
  onClick?: () => void;
};

const defaultItems: Item[] = [
  {
    prefix: "Start with the",
    label: "Startup IQ Score",
    postfix: "to figure out where you are.",
    disabled: false,
    onClick: () => window.scrollTo({ top: 0, behavior: "smooth" }),
  },
  {
    prefix: "Create a",
    label: "Startup IQ Account",
    postfix: "to save your progress.",
    disabled: false,
    onClick: () => (window.location.href = "/auth"),
  },
  {
    prefix: "You will get a",
    label: "Startup IQ Checklist",
    postfix: "of DO's and DON'Ts relevant to your stage.",
    disabled: true,
  },
  {
    prefix: "We will give you a custom",
    label: "Startup IQ Canvas",
    postfix: "to help you build a business.",
    disabled: true,
  },
  {
    prefix: "And help you create a custom",
    label: "Startup IQ Pitch Deck",
    postfix: "to help you raise funds.",
    disabled: false,
    onClick: () => (window.location.href = "/pitch-deck"),
  },
  {
    prefix: "And lastly, prepare your",
    label: "Startup IQ Funding",
    postfix: "application.",
    disabled: false,
    onClick: () => (window.location.href = "/funding"),
  },
  {
    prefix: "You can ask",
    label: "Questions",
    postfix: "any time along the way.",
    disabled: false,
    onClick: () => (window.location.href = "/questions"),
  },
];

const dashboardItems: Item[] = [
  {
    prefix: "You will get a",
    label: "Startup IQ checklist",
    postfix: "of DO's and DON'Ts relevant to your stage.",
    disabled: true,
  },
  {
    prefix: "We will give you a custom",
    label: "Startup IQ canvas",
    postfix: "to help you build a business.",
    disabled: true,
  },
  {
    prefix: "And lastly you get a",
    label: "Startup IQ pitch deck",
    postfix: "template to help you raise funds.",
    disabled: false,
    onClick: () => (window.location.href = "/my-pitch-deck"),
  },
  {
    prefix: "Prepare your",
    label: "Startup IQ funding",
    postfix: "application.",
    disabled: false,
    onClick: () => (window.location.href = "/my-funding"),
  },

  {
    prefix: "You can ask",
    label: "Questions",
    postfix: "any time along the way.",
    disabled: false,
    onClick: () => (window.location.href = "/my-questions"),
  },
];

type WhatCanYouDoProps = {
  page: "dashboard" | "default";
};

export const WhatCanYouDo = ({ page }: WhatCanYouDoProps) => {
  const items = page === "dashboard" ? dashboardItems : defaultItems;

  return (
    <div tw="w-full">
      <Heading tw="text-primary-500 text-center">
        What Can You Do + When We Come In.
      </Heading>

      {items.map((item, i) => (
        <div key={i} tw="flex mt-10 mb-4">
          <div tw="flex-grow w-full text-right pt-[0.25rem]">{item.prefix}</div>
          <div tw="flex-grow-0 text-center">
            <Button disabled={item.disabled} onClick={item.onClick}>
              {item.label}
            </Button>
          </div>
          <div tw="flex-grow w-full text-left pt-[0.25rem]">{item.postfix}</div>
        </div>
      ))}
    </div>
  );
};
