import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/configureStore";
import { ProductModel } from "./schemas";
import { createApiCall } from "store/fetch";
import { CollectionResultModel } from "store/types";

const initialState: { all: ProductModel[] } = {
  all: [],
};

export const fetchProducts = createApiCall<CollectionResultModel<ProductModel>>(
  "products/fetchProducts",
  "GET",
  () => "/products"
);

export const fetchProduct = createApiCall<ProductModel, string>(
  "products/fetchProduct",
  "GET",
  (productId) => `/products/${productId}`
);

export const createProduct = createApiCall<ProductModel, Partial<ProductModel>>(
  "products/createProduct",
  "POST",
  () => "/products",
  {
    getBody: (body) => body,
  }
);

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.all = action.payload.items;
    });
    builder.addCase(fetchProduct.fulfilled, (state, action) => {
      const index = state.all.findIndex((p) => p.id === action.payload.id);
      if (index === -1) {
        state.all.push(action.payload);
      } else {
        state.all[index] = action.payload;
      }
    });
    builder.addCase(createProduct.fulfilled, (state, action) => {
      state.all.push(action.payload);
    });
  },
});

export const selectProducts = (state: RootState) => state.products.all;
export const selectProduct = (state: RootState, productId: string) =>
  state.products.all.find((p) => p.id === productId);

export default productsSlice.reducer;
