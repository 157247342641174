import React, { useCallback, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/contact.png";
import * as EmailValidator from "email-validator";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { Alert } from "reactstrap";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-7/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-5/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`; //tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.div`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

function ContactForm({
  subheading = "Contact Us",
  heading = (
    <>
      Feel free to <span tw="text-primary-500">get in touch</span>
      <wbr /> with us.
    </>
  ),
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const submitContactForm = useCallback(async () => {
    setDisableSubmitButton(true);
    let fname = (name || "").trim();
    let femailAddress = (emailAddress || "").trim();
    let fmessage = (message || "").trim();
    if (!femailAddress || !EmailValidator.validate(femailAddress)) {
      setErrorMessage(`Invalid email address`);
    } else if (!fname) {
      setErrorMessage(`Invalid name`);
    } else if (!fmessage) {
      setErrorMessage(`Please fill in the message`);
    } else {
      const db = getFirestore();
      try {
        const docRef = await addDoc(collection(db, "mail"), {
          to: "contact@startup-iq.com",
          cc: [femailAddress],
          message: {
            subject: `New contact form submitted by ${fname}`,
            text: `Name: ${fname}
Email: ${femailAddress}
Message:
${fmessage}`,
          },
        });
        setErrorMessage("");
        setSuccessMessage(
          `Thanks for contacting Startup IQ! Your form has been submitted.`
        );
      } catch (error) {
        console.error(error);
        setErrorMessage(`Failed to submit the contact form`);
      }
    }
    setDisableSubmitButton(false);
  }, [name, emailAddress, message]);

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form // action={formAction} method={formMethod}
            >
              <Input
                type="email"
                name="email"
                placeholder="Your Email Address"
                value={emailAddress}
                onChange={(event) => setEmailAddress(event.target.value)}
              />
              <Input
                type="text"
                name="name"
                placeholder="Your Name"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
              <Textarea
                name="message"
                placeholder="Your Message Here"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              />
              {errorMessage && (
                <Alert color={"danger"} className={"mt-4 mb-0"}>
                  {errorMessage}
                </Alert>
              )}
              {successMessage ? (
                <Alert color={"success"} className={"mt-4"}>
                  {successMessage}
                </Alert>
              ) : (
                <SubmitButton
                  type="submit"
                  onClick={submitContactForm}
                  disabled={disableSubmitButton}
                >
                  {submitButtonText}
                </SubmitButton>
              )}
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
}

export default ContactForm;
