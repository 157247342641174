import {
  createAsyncThunk,
  SerializedError,
  miniSerializeError,
} from "@reduxjs/toolkit";
import { API_URL } from "./config";
import { RootState } from "../store/configureStore";

export const responseOk = async (response: Response) => {
  if (response.ok) {
    return Promise.resolve(response);
  } else {
    let content: unknown = undefined;
    try {
      content = await response.json();
    } catch {}
    return Promise.reject({ response, content });
  }
};

const serializeError = (
  error: unknown
): SerializedError & { response: { status: number; content?: unknown } } => {
  const { response, content } = error as { response: Response; content: any };

  const defaultError = miniSerializeError(error);

  const additionalInfo = { response: { status: response?.status, content } };

  return { ...defaultError, ...additionalInfo };
};

type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export const createApiCall = <Returned = void, Arguments = void>(
  typePrefix: string,
  method: HttpMethod,
  path: (args: Arguments) => string,
  options?: {
    getBody?: (args: Arguments) => unknown;
    getResponse?: (response: unknown) => Returned;
    returnVoid?: boolean;
  }
) => {
  const headers: Record<string, string> = {
    Accept: "application/json",
  };
  if (method === "POST" || method === "PUT" || method === "PATCH") {
    headers["Content-Type"] = "application/json";
  }

  return createAsyncThunk<Returned, Arguments>(
    typePrefix,
    async (args, { getState }) => {
      const state = getState() as RootState;
      const token = state.auth.authToken;
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      const returnVoid = options?.returnVoid || method === "DELETE";

      return await fetch(`${API_URL}${path(args)}`, {
        headers,
        method,
        body:
          options?.getBody === undefined
            ? undefined
            : JSON.stringify(options.getBody(args)),
      })
        .then(responseOk)
        .then((r) => {
          if (returnVoid) {
            return r;
          } else {
            return r.json();
          }
        })
        .then((r) => {
          if (returnVoid) {
            return undefined;
          } else if (options?.getResponse !== undefined) {
            return options.getResponse(r);
          } else {
            return r;
          }
        });
    },
    { serializeError }
  );
};
