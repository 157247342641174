import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/configureStore";
import { UserModel } from "./schemas";
import { createApiCall } from "store/fetch";
import { CollectionResultModel } from "store/types";

const initialState: {
  all: UserModel[];
} = { all: [] };

export const fetchUsers = createApiCall<CollectionResultModel<UserModel>>(
  "users/fetchUsers",
  "GET",
  () => "/users"
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.all = action.payload.items;
    });
  },
});

export const selectUsers = (state: RootState) => state.users.all;

export default usersSlice.reducer;
