import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import {
  FundingProductListing,
  PitchDeckProductListing,
  QuestionProductListing,
} from "components/products/ProductListings";
import { useFeatureFlags } from "hooks/useFeatureFlags";

const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

export const ProfileProducts = () => {
  const featureFlags = useFeatureFlags();

  return (
    <React.Fragment>
      {featureFlags.showQuestionsOnly ? (
        <React.Fragment>
          <div tw="flex justify-center">
            <div tw="w-full lg:w-1/3">
              <QuestionProductListing />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Paragraph tw="text-center">
            At this point in the process, you have 3 options:
          </Paragraph>
          <div tw="flex flex-wrap lg:flex-nowrap">
            <QuestionProductListing />
            <PitchDeckProductListing />
            <FundingProductListing />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
