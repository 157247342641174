import Footer from "components/footers/footer";
import ContactForm from "components/forms/ContactForm";
import { Header } from "components/headers";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import tw from "twin.macro";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";

export default function ContactPage() {
  return (
    <AnimationRevealPage disabled>
      <Header></Header>
      <ContactForm
        subheading={""}
        heading={
          <>
            Contact <span tw="text-primary-500">Startup IQ</span> for any
            questions you might have
          </>
        }
        description={""}
      ></ContactForm>
      <Footer></Footer>
    </AnimationRevealPage>
  );
}
