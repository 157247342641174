import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React, { useState } from "react";

import { selectCurrentProfile } from "store/profiles/slice";
import { ScoreDisplay } from "components/quiz/ScoreDisplay";
import { useAppSelector } from "store/types";
import { Quiz } from "components/quiz/Quiz";

const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const Button = tw.button`w-full inline-block bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-2 sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`;

export const ProfileScore = () => {
  const profile = useAppSelector(selectCurrentProfile);
  const hasScore = profile?.score !== undefined && profile?.score !== null;

  const [showQuiz, setShowQuiz] = useState(false);

  if (showQuiz) {
    return <Quiz />;
  }

  return (
    <div tw="mt-4 flex flex-col items-center">
      <React.Fragment>
        {profile !== undefined && (
          <>
            <ScoreDisplay value={profile?.score ?? 0} />
            {hasScore && (
              <Paragraph>
                If anything has changed since the last time you calculated your
                score,
              </Paragraph>
            )}
          </>
        )}
      </React.Fragment>
      <Button onClick={() => setShowQuiz(true)}>
        {hasScore ? "Re-take" : "Take"} the test
      </Button>
    </div>
  );
};
