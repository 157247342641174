import { createSlice } from "@reduxjs/toolkit";
import { createApiCall } from "store/fetch";

const initialState: {} = {};

export const sendEmail = createApiCall<
  void,
  {
    to: string;
    subject: string;
    body: string;
  }
>(
  "admin/sendEmail",
  "POST",
  (arg) =>
    `/admin/emails?to=${arg.to}&subject=${
      arg.subject
    }&body=${window.encodeURIComponent(arg.body)}`
);

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export default adminSlice.reducer;
