import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React from "react";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/types";
import {
  fetchStartupSupporterProfiles,
  selectStartupSupporterProfiles,
} from "store/startupSupporters/slice";
import { fetchQuizSpecs } from "store/quizzes/slice";
import { QuizAnswers } from "./QuizAnswers";
import { Table } from "components/table";
import { StartupSupporterProfileModel } from "store/startupSupporters/schemas";

const Tag = tw.span`bg-primary-400 rounded-full px-3 py-1 text-white font-bold text-sm`;

type StartupSupporterUsersProps = {
  startupSupporterId: string;
};
export const StartupSupporterUsers = ({
  startupSupporterId,
}: StartupSupporterUsersProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchStartupSupporterProfiles(startupSupporterId));
    dispatch(fetchQuizSpecs());
  }, []);

  const profiles = useAppSelector((state) =>
    selectStartupSupporterProfiles(state, startupSupporterId)
  );

  return (
    <div>
      <div tw="my-4">
        <Table
          columns={[
            {
              name: "Name",
              render: (p) => <div>{p.userName ?? `Unknown (${p.userId})`}</div>,
            },
            {
              name: "Email",
              field: "emailAddress",
            },
            {
              name: "Score",
              field: "score",
              sortBy: (p) => (p.score ?? 0).toString(),
            },
            {
              name: "Responses",
              render: (p) =>
                p.quizAnswers ? (
                  <QuizAnswers
                    specId={p.quizSpecId}
                    answers={p.quizAnswers}
                    questions={[
                      "current-need",
                      "how-can-we-help",
                      "secured-funding",
                    ]}
                  />
                ) : null,
            },
            {
              name: "Tags",
              render: (p) => (
                <div>
                  {(p.tags ?? []).map((t, j) => (
                    <Tag key={j}>{t}</Tag>
                  ))}
                </div>
              ),
              sortBy: (p) => [...(p.tags ?? [])].join(","),
            },
          ]}
          data={profiles}
        />
      </div>
    </div>
  );
};
