import { selectTags } from "store/auth/slice";
import { useAppSelector } from "store/types";

type FeatureFlags = {
  showQuestionsOnly: boolean;
  showNavigation: boolean;
};

export const useFeatureFlags = (): FeatureFlags => {
  const tags = useAppSelector(selectTags);

  const isCohortUser =
    tags.indexOf("Ureeka") !== -1 || tags.indexOf("Extenda") !== -1;

  return {
    showQuestionsOnly: isCohortUser,
    showNavigation: !isCohortUser,
  };
};
