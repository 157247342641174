import tw from "twin.macro";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import { useAppSelector } from "store/types";
import { selectCurrentProfile } from "store/profiles/slice";
import { Heading } from "components/misc/Headings";
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

type LabeledCircleProps = {
  label: string;
};
const LabeledCircle = ({ label }: LabeledCircleProps) => (
  <div>
    <div tw="h-32 w-32 rounded-full bg-primary-500 flex">
      <div tw="w-full text-center justify-self-center self-center text-4xl font-bold text-white">
        {label}
      </div>
    </div>
  </div>
);

type ProbabilitySectionProps = {
  percent: string;
  label: string;
};
const ProbabilitySection = ({ percent, label }: ProbabilitySectionProps) => (
  <div tw="w-full lg:w-1/3 flex items-center mt-2">
    <LabeledCircle label={percent} />
    <div tw="ml-4">{label}</div>
  </div>
);

export const Probabilities = () => {
  const profile = useAppSelector(selectCurrentProfile);

  if (!profile?.score) {
    return null;
  }

  return (
    <div>
      <div tw="text-center">
        <Heading>Based on your score, you have...</Heading>
      </div>
      <div tw="flex justify-evenly mt-10 flex-wrap lg:flex-nowrap">
        <ProbabilitySection
          percent={`${(profile.score / 11).toFixed(2)}%`}
          label={"Chance of being accepted in a startup bootcamp."}
        />
        <ProbabilitySection
          percent={`${(profile.score / (11 * 11)).toFixed(2)}%`}
          label={" Chance of being funded by an accelerator or an incubator."}
        />
      </div>
      <div tw="mt-4 w-full flex justify-center">
        <Paragraph tw="w-2/3 text-center">
          This doesn't mean you should give up. But it makes a stronger case for
          bootstrapping while continue to improve your score through the right
          strategy and tactics.
        </Paragraph>
      </div>
    </div>
  );
};
