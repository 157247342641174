import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";

import { Question } from "./types";
import { QuizQuestionOption } from "./QuizQuestionOption";

const Paragraph = tw.p`text-base xl:text-lg text-center font-semibold`;
const DescriptionParagraph = tw(Paragraph)`text-purple-600`;

const TwoColumn = tw.div`flex flex-row justify-between max-w-screen-xl`;
const TwoColumnInner = tw.div`w-1/2 mx-4`;
const OneColumn = tw.div`flex flex-col justify-between max-w-screen-xl mx-auto w-2/3`;

const range = (start: number, stop: number, step?: number) =>
  Array.from(
    { length: (stop - start) / (step ?? 1) + 1 },
    (_, i) => start + i * (step ?? 1)
  );

export const QuizQuestion = ({ question }: { question: Question }) => {
  const { title, description, options } = question;

  return (
    <React.Fragment>
      {title && <Paragraph>{title}</Paragraph>}
      {description && (
        <DescriptionParagraph>{description}</DescriptionParagraph>
      )}

      {options.length > 4 && options.length % 2 === 0 ? (
        range(0, options.length / 2).map((i) => {
          const optionsSegment = options.slice(2 * i, 2 * i + 2);
          return (
            <TwoColumn key={i}>
              {optionsSegment.map((option, j) => (
                <TwoColumnInner key={j}>
                  <QuizQuestionOption
                    questionId={question.id}
                    type={question.type}
                    option={option}
                  />
                </TwoColumnInner>
              ))}
            </TwoColumn>
          );
        })
      ) : (
        <OneColumn>
          {options.map((option, i) => (
            <QuizQuestionOption
              key={i}
              questionId={question.id}
              type={question.type}
              option={option}
            />
          ))}
        </OneColumn>
      )}
    </React.Fragment>
  );
};
