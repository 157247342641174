import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { parseISO, format } from "date-fns";
import { ReactComponent as QuoteIconBase } from "images/quotes-l.svg";
import { QuestionResponse } from "store/questions/schemas";

const QuoteContainer = tw.div`relative`;
const QuoteIcon = tw(
  QuoteIconBase
)`absolute opacity-15 top-0 left-0 transform -translate-y-2 -translate-x-1/2 sm:-translate-x-full w-10 fill-current text-primary-500`;
const QuoteText = tw.blockquote`font-medium sm:font-normal relative text-sm sm:text-xl text-center sm:text-left`;

type QuestionResponseDisplayProps = {
  body: string;
  created: string;
  name?: string;
};
const QuestionResponseDisplay = ({
  body,
  created,
  name,
}: QuestionResponseDisplayProps) => {
  return (
    <div tw="bg-gray-200 px-4 py-2 ml-20 mt-4 rounded-lg text-right inline-block">
      <QuoteContainer>
        <QuoteText tw="text-right">{body}</QuoteText>
        <cite>
          Answered on {format(parseISO(created), "MMMM d")} by{" "}
          {name ?? "Unknown"}
        </cite>
      </QuoteContainer>
    </div>
  );
};

type QuestionDiplay = {
  body: string;
  created: string;
  responses: QuestionResponse[];
};
export const QuestionDisplay = ({
  body,
  created,
  responses,
}: QuestionDiplay) => {
  return (
    <div>
      <QuoteContainer>
        <QuoteIcon />
        <QuoteText>{body}</QuoteText>
        <cite>Asked on {format(parseISO(created), "MMMM d")}</cite>
      </QuoteContainer>
      {responses.length === 0 ? (
        <em tw="ml-20 mt-6 block">No answers yet</em>
      ) : null}
      {responses.map((r, i) => (
        <QuestionResponseDisplay
          key={i}
          body={r.body}
          created={r.created}
          name={r.user?.name}
        />
      ))}
    </div>
  );
};
