import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React from "react";
import { PurchaseProduct } from "components/products/PurchaseProduct";
import {
  FIVE_QUESTIONS,
  ONE_QUESTION,
  THREE_QUESTIONS,
} from "constants/products";
import { SharedLayout, SignUpWithRedirect } from "./Shared";

const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

export const QuestionsProductPage = () => {
  return (
    <SharedLayout>
      <>
        <Heading tw="text-primary-500">Questions</Heading>
        <Paragraph>
          You have a lot to process right now. You got a domain name, a logo,
          and registered your business. Maybe you printed a few tshirts and
          stickers. Now what? You have questions about where to go next.
        </Paragraph>
        <Paragraph>
          Ok, maybe you've made more progress than that, but the questions are
          still there.
        </Paragraph>
        <Paragraph>Ask them now.</Paragraph>
        <PurchaseProduct
          type="question"
          labelFn={({ price }) => `1 Question - ${price}`}
          productId={ONE_QUESTION}
          expanded
        />
        <PurchaseProduct
          type="question"
          labelFn={({ price }) => `3 Questions - ${price}`}
          productId={THREE_QUESTIONS}
          expanded
        />
        <PurchaseProduct
          type="question"
          labelFn={({ price }) => `5 Questions - ${price}`}
          productId={FIVE_QUESTIONS}
          expanded
        />
      </>
      <SignUpWithRedirect />
    </SharedLayout>
  );
};
