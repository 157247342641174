import React, { useState } from "react";
import ReactModal from "react-modal";

type ModalProps = {
  children: React.ReactNode;
  className?: string;
  label: string;
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "100vh",
  },
};

export const Modal = ({ label, children }: ModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      <button onClick={() => setIsOpen(true)}>{label}</button>
      <ReactModal
        isOpen={isOpen}
        shouldCloseOnOverlayClick
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
      >
        {children}
      </ReactModal>
    </React.Fragment>
  );
};
