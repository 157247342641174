import Footer from "components/footers/footer";
import { Header } from "components/headers";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React, { useEffect } from "react";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useAppDispatch, useAppSelector } from "store/types";
import { fetchUsers, selectUsers } from "store/users/slice";
import { UserModel } from "store/users/schemas";
import { fetchProfiles, selectProfiles } from "store/profiles/slice";
import {
  fetchStartupSupporters,
  selectStartupSupporters,
} from "store/startupSupporters/slice";
import { StartupSupporterModel } from "store/startupSupporters/schemas";
import { fetchProducts, selectProducts } from "store/products/slice";
import { ProductModel } from "store/products/schemas";
import {
  fetchAllProvisionalQuizResponses,
  fetchAllQuizResponses,
  fetchQuizSpecs,
  selectProvisionalQuizResponses,
} from "store/quizzes/slice";
import { Modal } from "components/modal";
import { StartupSupporterUsers } from "components/admin/StartupSupporterUsers";
import { ProvisionalQuizResponseModel } from "store/quizzes/schemas";
import { ProfileQuizzes } from "components/admin/ProfileQuizzes";
import { QuizResponseDetails } from "components/admin/QuizResponseDetails";
import { Table } from "components/table";

const OneColumn = tw.div`max-w-screen-xl mx-auto py-8 md:py-8`;
const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;

export default function AdminPage() {
  const { user } = useAuthenticatedUser();
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);
  const profiles = useAppSelector(selectProfiles);
  const startupSupporters = useAppSelector(selectStartupSupporters);
  const products = useAppSelector(selectProducts);
  const provisionalResponses = useAppSelector(selectProvisionalQuizResponses);

  useEffect(() => {
    if (user) {
      dispatch(fetchUsers());
      dispatch(fetchProfiles());
      dispatch(fetchStartupSupporters());
      dispatch(fetchProducts());
      dispatch(fetchQuizSpecs());
      dispatch(fetchAllQuizResponses());
      dispatch(fetchAllProvisionalQuizResponses());
    }
  }, [user]);

  return (
    <AnimationRevealPage disabled>
      <Header></Header>
      <div tw="relative">
        <OneColumn>
          <Heading>Users</Heading>
          <Table<UserModel>
            data={users}
            columns={[
              { name: "ID", field: "id" },
              {
                name: "Profile ID",
                render: (user) =>
                  profiles.find((p) => p.userId === user.id)?.id,
              },
              {
                name: "Login",
                render: (user) => {
                  return (user.loginProviders ?? []).join(", ");
                },
              },
              { name: "Name", field: "name" },
              { name: "Email", field: "emailAddress" },
              { name: "Role", field: "role" },
              {
                name: "Score",
                render: (user) => {
                  const profile = profiles.find((p) => p.userId === user.id);
                  return (
                    <Modal label={`${profile?.score ?? ""}`}>
                      <ProfileQuizzes profile={profile} />
                    </Modal>
                  );
                },
              },
            ]}
          />
          {/* <Heading>Profiles</Heading>
          <Table<ProfileModel>
            data={profiles}
            columns={[
              { name: "ID", field: "id" },
              { name: "User ID", field: "userId" },
              { name: "Score", field: "score" },
              {
                name: "State",
                field: "state",
              },
            ]}
          /> */}

          <Heading>Provisional Responses</Heading>
          <Table<ProvisionalQuizResponseModel>
            data={provisionalResponses}
            columns={[
              { name: "ID", field: "id" },
              { name: "Profile ID", field: "profileId" },
              { name: "Created", field: "created" },
              {
                name: "Answers",
                render: (provisionalResponse) => {
                  const numAnswers = Object.keys(
                    provisionalResponse.answers
                  ).length;
                  return (
                    <Modal
                      label={`${numAnswers} ${
                        numAnswers === 1 ? "answer" : "answers"
                      }`}
                    >
                      <QuizResponseDetails response={provisionalResponse} />
                    </Modal>
                  );
                },
              },
            ]}
          />

          <Heading>Startup Supporters</Heading>
          <Table<StartupSupporterModel>
            data={startupSupporters}
            columns={[
              { name: "ID", field: "id" },
              {
                name: "Name",
                render: (startupSupporter) => {
                  return (
                    <Modal label={startupSupporter.name}>
                      <StartupSupporterUsers
                        startupSupporter={startupSupporter}
                      />
                    </Modal>
                  );
                },
              },
            ]}
          />

          <Heading>Products</Heading>
          <Table<ProductModel>
            data={products}
            columns={[
              { name: "ID", field: "id" },
              { name: "Name", field: "name" },
              { name: "Description", field: "description" },
              { name: "Image", field: "imageUrl" },
              { name: "Price Decimal", field: "priceDecimal" },
              { name: "Display Price", field: "displayPrice" },
            ]}
          />
        </OneColumn>
      </div>
      <Footer></Footer>
    </AnimationRevealPage>
  );
}
