import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/configureStore";
import { StartupSupporterModel, StartupSupporterProfileModel } from "./schemas";
import { createApiCall } from "store/fetch";
import { CollectionResultModel } from "store/types";
import { QuestionModel } from "store/questions/schemas";
import { ProfileModel } from "store/profiles/schemas";
import { createQuestionResponse } from "store/questions/slice";
import { UserModel } from "store/users/schemas";

const initialState: {
  all: StartupSupporterModel[];
  questions: Record<string, QuestionModel[]>;
  profiles: Record<string, StartupSupporterProfileModel[]>;
} = { all: [], questions: {}, profiles: {} };

export const fetchStartupSupporters = createApiCall<
  CollectionResultModel<StartupSupporterModel>
>(
  "startupSupporters/fetchStartupSupporters",
  "GET",
  () => "/startup-supporters"
);

export const fetchStartupSupporterProfiles = createApiCall<
  CollectionResultModel<StartupSupporterProfileModel>,
  string
>(
  "startupSupporters/fetchStartupSupporterProfiles",
  "GET",
  (startupSupporterId) => `/startup-supporters/${startupSupporterId}/profiles`
);

export const fetchStartupSupporterQuestions = createApiCall<
  CollectionResultModel<QuestionModel>,
  string
>(
  "startupSupporters/fetchStartupSupporterQuestions",
  "GET",
  (startupSupporterId) => `/startup-supporters/${startupSupporterId}/questions`
);

export const fetchStartupSupporterUsers = createApiCall<
  CollectionResultModel<UserModel>,
  string
>(
  "startupSupporters/fetchStartupSupporterUsers",
  "GET",
  (startupSupporterId) => `/startup-supporters/${startupSupporterId}/users`
);

export const startupSupportersSlice = createSlice({
  name: "startupSupporters",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStartupSupporters.fulfilled, (state, action) => {
      state.all = action.payload.items;
    });
    builder.addCase(
      fetchStartupSupporterQuestions.fulfilled,
      (state, action) => {
        state.questions[action.meta.arg] = action.payload.items;
      }
    );
    builder.addCase(
      fetchStartupSupporterProfiles.fulfilled,
      (state, action) => {
        state.profiles[action.meta.arg] = action.payload.items;
      }
    );
    builder.addCase(createQuestionResponse.fulfilled, (state, action) => {
      for (const questions of Object.values(state.questions)) {
        const question = questions.find(
          (q) => q.id === action.meta.arg.questionId
        );
        if (question) {
          question.responses.push(action.payload);
        }
      }
    });
  },
});

export const selectStartupSupporters = (state: RootState) =>
  state.startupSupporters.all;

export const selectStartupSupporterQuestions = (
  state: RootState,
  startupSupporterId: string
) => state.startupSupporters.questions[startupSupporterId] ?? [];

export const selectStartupSupporterProfiles = (
  state: RootState,
  startupSupporterId: string
) => state.startupSupporters.profiles[startupSupporterId] ?? [];

export default startupSupportersSlice.reducer;
