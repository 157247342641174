import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React, { useState } from "react";
import { Link } from "components/link";
import { SharedLayout, SignUpWithRedirect } from "./Shared";

const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const BaseButton = tw.button`inline-block font-bold my-4 sm:my-2 rounded-lg py-6 px-4 mr-6 sm:leading-none focus:outline-none transition duration-300`;

const Button = styled(BaseButton)((props: { disabled?: boolean }) => [
  tw`w-full`,
  props.disabled
    ? tw`bg-gray-500 text-gray-100 cursor-default`
    : tw`bg-primary-500 text-gray-100 hover:bg-primary-900`,
]);

export const FundingProductPage = () => {
  const [viewMore, setViewMore] = useState(false);

  return (
    <SharedLayout>
      <>
        <Heading tw="text-primary-500">Funding</Heading>
        {viewMore ? (
          <>
            <Paragraph>
              That's where we come in. We get your business ready, we establish
              the right time to look for external funding, and we match you with
              the right partner. Don't be surprised it's not the Y Combinator.
              There are many other good accelerators and incubators out there.
            </Paragraph>
            <SignUpWithRedirect
              showHeading={false}
              showForm={false}
              showModeSwitcher={false}
              onComplete={() => (window.location.href = "/my-funding")}
              redirect={viewMore ? "/my-funding" : undefined}
              otherMethodsPrefix="Sign in or Sign up with"
              otherMethodsSuffix="to get started"
            />
          </>
        ) : (
          <>
            <Paragraph>
              You don't need to have your{" "}
              <Link href="/pitch-deck">Pitch Deck</Link> finished to start
              looking for external funding. You just have to be ready before you
              need it. Running out of money is the second biggest reason why
              most startups fail.
            </Paragraph>
            <Paragraph>
              We do train for, and recommend bootstrapping. But many times an
              external source can help you significantly to get you where you
              want to be.
            </Paragraph>
            <Paragraph>
              You've probably seen our funding diagram on the{" "}
              <Link href="/why">Why page</Link>. Funding sources align well with
              the stage of your startup.
            </Paragraph>
            <Paragraph>
              However, finding the right funding partner is not an easy task.
            </Paragraph>
            <Button tw="mt-12" onClick={() => setViewMore(true)}>
              I'm intrigued. Tell me more.
            </Button>
          </>
        )}
      </>
      <>
        <SignUpWithRedirect
          onComplete={() => (window.location.href = "/my-funding")}
          redirect={viewMore ? "/my-funding" : undefined}
          showOtherMethods={!viewMore}
        />
      </>
    </SharedLayout>
  );
};
