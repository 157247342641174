import Footer from "components/footers/footer";
import { Header } from "components/headers";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import tw from "twin.macro";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import styled from "styled-components";
import IllustrationSrc from "images/Growth Stages and Funding Sources.png";

const Container = tw.div`relative`;
const OneColumn = tw.div`max-w-screen-xl mx-auto py-4 md:py-8`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-7/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props: { textOnLeft: boolean }) => [
  tw`md:w-5/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props: { imageSrc: string }) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const Paragraph = tw.p`mb-4 text-base xl:text-lg`;

const List = tw.ul`list-disc`;
const ListItem = tw.li`ml-8 my-2 text-base xl:text-lg`;

export default function WhyPage() {
  return (
    <AnimationRevealPage disabled>
      <Header></Header>
      <Container>
        <OneColumn>
          <Paragraph>
            Our ideas started forming between 2020 and 2022, out of the Covid
            unemployment rollercoaster, the Big Resignation, and the Big Tech
            layoffs. We asked ourselves:
          </Paragraph>

          <List>
            <ListItem>What are people going to do next?</ListItem>
            <ListItem>
              How many will become entrepreneurs for the first time in their
              lives?
            </ListItem>
            <ListItem>
              Do they know how to navigate the self-employment and the startup
              world.
            </ListItem>
          </List>
          <Paragraph>
            This is hard. For many individuals is gut wrecking. We still don’t
            know how many became entrepreneurs out of necessity, and how many
            did it to attempt a different way of living.
          </Paragraph>
        </OneColumn>
        <TwoColumn>
          <ImageColumn>
            <Image imageSrc={IllustrationSrc}></Image>
          </ImageColumn>
          <TextColumn textOnLeft={true}>
            <Paragraph>
              Starting a company is scary and difficult, and no matter how you
              got started, what keeps you going, and what your product/market
              fit is, you'll always encounter a barrier or a question you can't
              answer.
            </Paragraph>
            <Paragraph>
              You'll always need a partner. Sometimes it is a family member, a
              co-founder, or a supplier. Sometimes it's an angel investor, an
              accelerator, or an incubator.
            </Paragraph>
            <Paragraph>We are that partner.</Paragraph>
            <Paragraph>
              They can make a difference in the way you bootstrap, find your
              first customer, get revenue, stay profitable, or scale. Different
              programs and investors help you in different ways, it is important
              to understand what is the right program and funding for the stage
              where your business is at the moment.
            </Paragraph>
            <Paragraph>
              We can assess where you are, improve your pitch or business model,
              and match you with the right partner.
            </Paragraph>
          </TextColumn>
        </TwoColumn>
      </Container>
      <Footer></Footer>
    </AnimationRevealPage>
  );
}
