import { LINKEDIN_PROFILE } from "constants/localStorage";
import { getAuth, signOut } from "firebase/auth";

export const signOutCompletely = () => {
  // TODO: Clear out Redux store

  // LinkedIn stuff
  if (localStorage.getItem(LINKEDIN_PROFILE)) {
    localStorage.removeItem(LINKEDIN_PROFILE);
  }

  // Firebase stuff
  const auth = getAuth();
  signOut(auth)
    .then(() => {
      // Sign-out successful.
      window.location.href = "/";
    })
    .catch((error) => {
      // An error happened.
      console.error(error);
      alert("Failed to sign out: " + error.message);
    });
};
