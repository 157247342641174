import Footer from "components/footers/footer";
import { Header } from "components/headers";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React from "react";
import { useEffect } from "react";
import { useAppDispatch } from "store/types";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { Quiz } from "components/quiz/Quiz";

const OneColumn = tw.div`max-w-screen-xl mx-auto py-8 md:py-8`;
const TextArea = tw.textarea`w-full h-72 sm:h-48 resize-none border rounded border-primary-600 focus:outline-none transition duration-300 hocus:border-primary-500`;
const Button = tw.button`inline-block bg-primary-500 text-gray-100 font-bold my-4 sm:my-2 rounded-full py-2 sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`;
const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

export const FundingPage = () => {
  const dispatch = useAppDispatch();

  const { user } = useAuthenticatedUser();

  useEffect(() => {
    if (user) {
    }
  }, [user]);

  return (
    <AnimationRevealPage disabled>
      <Header></Header>
      <div tw="relative">
        <OneColumn>
          <div>
            <Heading tw="text-center text-primary-500 font-bold mb-8">
              Getting to Know You
            </Heading>

            <Quiz
              specId="funding"
              allowBack
              allowSkip
              temporary
              onComplete={() => console.log("Done")}
            />
          </div>
        </OneColumn>
      </div>
      <Footer></Footer>
    </AnimationRevealPage>
  );
};
