import TwitterIcon from "images/icons/StartupIQ-Twitter.png";
import LinkedinIcon from "images/icons/StartupIQ-LinkedIn.png";
import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";

// Footer
const Footer = tw.div`flex flex-col md:flex-row items-center justify-between mt-20 max-w-screen-xl mx-auto`;
const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black tracking-wider text-primary-500`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-700`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-100 text-gray-900 hover:bg-gray-400 transition duration-300 mr-2 last:mr-0`}
  img, svg {
    ${tw`w-6 h-6`}
  }
`;

function FooterComponent() {
  return (
    <Footer>
      <LogoContainer>
        {/*<LogoImg src={""} />*/}
        <LogoText>Startup IQ</LogoText>
      </LogoContainer>
      <CopywrightNotice>2022 &copy; all rights reserved</CopywrightNotice>
      <SocialLinksContainer>
        <SocialLink href="https://twitter.com/caumike/">
          <img src={TwitterIcon} alt="twitter" />
        </SocialLink>
        <SocialLink href="https://www.linkedin.com/in/queralt/">
          <img src={LinkedinIcon} alt="linkedin" />
        </SocialLink>
      </SocialLinksContainer>
    </Footer>
  );
}

export default FooterComponent;
