import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";

import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import {
  QuestionType,
  QuizSpecificationQuestionOptionModel,
} from "store/quizzes/schemas";

import { QuizContext } from "./QuizContext";

const Input = tw.input`mt-8 border px-3 rounded border-primary-600 py-6 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const TextArea = tw.textarea`mt-8 h-72 sm:h-48 resize-none border rounded border-primary-600 focus:outline-none transition duration-300 hocus:border-primary-500`;

const QuizButton = styled(PrimaryButtonBase)(
  (props: { selected?: boolean }) => [
    tw`inline-block mt-8 mb-8 p-8 w-full`,
    props.selected ? tw`bg-orange-500 hover:bg-orange-600` : undefined,
  ]
);

const QuizQuestionOptionInner = ({
  value,
  option,
  onChange,
}: {
  value: string;
  option: QuizSpecificationQuestionOptionModel;
  onChange: (value: string) => void;
}) => {
  if (option.type === "value") {
    const selected = value === option.id;

    return (
      <QuizButton
        selected={selected}
        onClick={(e) => {
          e.currentTarget.blur();
          onChange(selected ? "" : option.id);
        }}
      >
        {option.label}
      </QuizButton>
    );
  } else if (option.type === "number") {
    return (
      <Input
        type="number"
        name="number"
        min={1}
        placeholder="Please use your best estimate."
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
    );
  } else if (option.type === "text") {
    return (
      <TextArea
        name="text"
        value={value}
        maxLength={10000}
        onChange={(event) => onChange(event.target.value)}
      />
    );
  } else {
    console.log(`Unsupported option type: ${option.type}`);
    return null;
  }
};

export const QuizQuestionOption = ({
  questionId,
  type,
  option,
}: {
  questionId: string;
  type: QuestionType;
  option: QuizSpecificationQuestionOptionModel;
}) => {
  return (
    <QuizContext.Consumer>
      {({ onChange, answers }) => (
        <QuizQuestionOptionInner
          value={
            (answers[questionId] ?? []).find((a) => a.id === option.id)
              ?.value ?? ""
          }
          onChange={(value) => {
            const currentValue = { id: option.id, value };
            if (type === "discreteValues") {
              const updated = (answers[questionId] ?? []).filter(
                (a) => a.id !== option.id
              );

              if (value) {
                updated.push(currentValue);
              }
              onChange({ [questionId]: updated });
            } else if (type === "discreteValue") {
              if (value) {
                onChange({ [questionId]: [currentValue] });
              }
            } else if (type === "numeric") {
              onChange({ [questionId]: value ? [currentValue] : [] });
            } else if (type === "text") {
              onChange({ [questionId]: [currentValue] });
            }
          }}
          option={option}
        />
      )}
    </QuizContext.Consumer>
  );
};
