import {
  FUNDING_PRICE,
  FIVE_QUESTION_PRICE,
  PITCH_DECK_PRICE,
} from "constants/products";
import React from "react";
import { ProductListing } from "./ProductListing";

export const QuestionProductListing = () => (
  <ProductListing
    title="Get your questions answered"
    description="You have a lot to process right now. Maybe you have questions. We are here to answer them, so you continue with confidence."
    price={`Starting at $${FIVE_QUESTION_PRICE / 5}`}
    onClick={() => {
      window.location.href = "/my-questions";
    }}
  />
);

export const PitchDeckProductListing = () => (
  <ProductListing
    title="Start creating your Pitch Deck"
    description="This service will help you create the content for 10 Essential slides that are part of any application deck for Funding, Incubation, Acceleration."
    price={`$${PITCH_DECK_PRICE}`}
    onClick={() => {
      window.location.href = "/my-pitch-deck";
    }}
  />
);

export const FundingProductListing = () => (
  <ProductListing
    title="Start applying for funding in your state"
    description="If you feel ready to apply for any outside investments, we'll show you top angels, VCs, incubators, and accelerators in your state."
    price={`$${FUNDING_PRICE}`}
    onClick={() => {
      window.location.href = "/my-funding";
    }}
  />
);
