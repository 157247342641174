import { useMemo, useCallback } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";

import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { QuestionAnswers } from "./types";
import { ScoreDisplay } from "./ScoreDisplay";

import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useAppDispatch, useAppSelector } from "store/types";
import { selectIsAuthenticated, setToken } from "store/auth/slice";
import { createQuizResponse } from "store/profiles/slice";
import { selectQuizSpec } from "store/quizzes/slice";
import { getCurrentScore } from "helpers/quiz";

const Paragraph = tw.p`text-base xl:text-lg text-center font-semibold`;

type ScoreReportProps = {
  specId: string;
  answers: Partial<QuestionAnswers>;
  onSave?: () => void;
};

export const ScoreReport = ({ specId, answers, onSave }: ScoreReportProps) => {
  const dispatch = useAppDispatch();

  const spec = useAppSelector((state) => selectQuizSpec(state, specId));

  const questions = useMemo(() => {
    return spec?.questions ?? [];
  }, [spec]);

  const currentScore = useMemo(
    () => getCurrentScore(answers, questions),
    [answers, questions]
  );

  const signInWithGoogle = useCallback(() => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const token = await result.user.getIdToken();
        // TODO: Make this process more streamlined
        dispatch(setToken(token));
        dispatch(createQuizResponse({ specId, answers }))
          .unwrap()
          .then(() => {
            onSave && onSave();
          });
      })
      .catch((error) => {
        console.log("Failed to sign in or save results");
      });
  }, []);

  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  return (
    <div>
      <Paragraph>Your Startup IQ score is:</Paragraph>

      <ScoreDisplay value={currentScore} />

      <Paragraph>You are off to a good start.</Paragraph>

      {!isAuthenticated && (
        <div tw="flex justify-center">
          <PrimaryButtonBase onClick={signInWithGoogle}>
            Save my IQ score and show my profile
          </PrimaryButtonBase>
        </div>
      )}
    </div>
  );
};
