import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React from "react";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/types";
import { QuestionDisplay } from "components/questions/QuestionDisplay";
import {
  fetchStartupSupporterQuestions,
  selectStartupSupporterQuestions,
} from "store/startupSupporters/slice";
import { AnswerQuestion } from "components/questions/AnswerQuestion";

const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-baseline max-w-screen-xl mx-auto py-2 md:py-4`;
const LeftColumn = tw.div`relative lg:w-6/12 text-left max-w-lg lg:max-w-none`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

type StartupSupporterQuestionsProps = {
  startupSupporterId: string;
};
export const StartupSupporterQuestions = ({
  startupSupporterId,
}: StartupSupporterQuestionsProps) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchStartupSupporterQuestions(startupSupporterId));
  }, []);

  const questions = useAppSelector((state) =>
    selectStartupSupporterQuestions(state, startupSupporterId)
  );

  return (
    <div>
      {questions.map((q, i) => (
        <div key={i} tw="my-4">
          <TwoColumn>
            <LeftColumn>
              <QuestionDisplay
                body={q.body}
                created={q.created}
                responses={q.responses}
              />
            </LeftColumn>
            <RightColumn>
              {q.responses.length === 0 && <AnswerQuestion questionId={q.id} />}
            </RightColumn>
          </TwoColumn>
        </div>
      ))}
    </div>
  );
};
