import Footer from "components/footers/footer";
import { Header } from "components/headers";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import FounderImage from "images/founder.jpg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;
const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const CenteredHeading = tw.p`text-center font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const FourColumn = tw.div`flex flex-col flex-wrap items-center justify-center sm:flex-row max-w-screen-xl mx-auto py-20 sm:py-24`;
const ClientCard = styled.div`
  ${tw`rounded-b block max-w-md mx-auto my-6 sm:max-w-none lg:w-3/12 md:w-4/12 sm:w-6/12`}

  img {
    max-height: 54px;
    margin: 0 auto;
  }
`;
const Button = tw.button`relative w-3/12 bg-primary-500 text-gray-100 p-2 font-bold rounded-full focus:outline-none hocus:bg-primary-700 hocus:text-gray-200 transition duration-300`;

const Service = styled.div`
  ${tw`relative text-center p-4`}
  h1 {
    ${tw`text-primary-500 text-2xl`}
  }
  p {
    ${tw`my-4`}
  }
`;

function Client({
  imageSrc = "",
  imageAlt = "",
  imageStyle = {},
  href = "#/",
}) {
  return (
    <ClientCard>
      <a href={href} target="_blank" rel="noreferrer">
        <img src={imageSrc} alt={imageAlt} style={imageStyle}></img>
      </a>
    </ClientCard>
  );
}

export default function AboutPage() {
  return (
    <AnimationRevealPage disabled>
      <Header></Header>
      <Container>
        <TwoColumn>
          <LeftColumn>
            <div tw="md:p-8">
              <img src={FounderImage} alt={"Michael Queralt"}></img>
            </div>
          </LeftColumn>
          <RightColumn>
            <Heading>
              Michael Queralt founded Startup IQ to help entrepreneurs start,
              ideate, and scale their business.
            </Heading>
            <Paragraph>
              As an entrepreneur with over 30 years of experience, and an Y
              Combinator alumnus, Michael bridges the gap between product,
              technology, and business objectives. His extensive experience with
              startups, B2B sales management, product development, funding, and
              early customer adoption provides a unique view for any
              organization that is looking to design innovation and achieve new
              revenue objectives with scalable processes.
            </Paragraph>
            <Paragraph>
              Following the principles of a customer-centric product, market,
              and fit approach, he has enabled numerous organizations to define
              their revenue strategies, adopt innovation and define sales and
              operations to drive top line revenues. His technology focus is on
              identities, authentication, privacy, and contextual decisions, and
              he has worked with organizations like the Department of Homeland
              Security Science and Technology, FIDO Alliance, DC Government, and
              John Hopkins Advance Physic Laboratory. His business expertise is
              on consultative sales, understanding end user requirements, and
              sales processes, which have been defined by working with
              organizations like Xerox, Vanstar, Compucom, and many of their
              corporate customers.
            </Paragraph>
          </RightColumn>
        </TwoColumn>
        <CenteredHeading>
          Proudly mentoring entrepreneurs from the following organizations
        </CenteredHeading>
        <FourColumn>
          <Client
            imageSrc={"/assets/images/clients/ureeka.png"}
            imageAlt="Ureeka"
            imageStyle={{ maxHeight: "36px" }}
            href="https://ureeka.biz/"
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/starta.png"}
            imageAlt="Starta"
            imageStyle={{ height: "70px", maxHeight: "70px" }}
            href="https://starta.vc/"
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/village-capital.png"}
            imageAlt="Village Capital"
            href="https://vilcap.com/"
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/aarp-foundation.jpg"}
            imageAlt="AARP Foundation"
            href="https://foundation.aarp.org"
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/pacific-community-ventures.png"}
            imageAlt="Pacificy Community Ventures"
            imageStyle={{ height: "80px", maxHeight: "80px" }}
            href="https://www.pacificcommunityventures.org/"
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/yscg.png"}
            imageAlt="YSCG"
            imageStyle={{ height: "80px", maxHeight: "80px" }}
            href="https://admissions.yale.edu/yale-startup-consulting-group"
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/connecticut-innovations.png"}
            imageAlt="Connecticut innovations"
            imageStyle={{ height: "70px", maxHeight: "70px" }}
            href="https://ctinnovations.com/"
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/celo-camp.png"}
            imageAlt="celo camp"
            imageStyle={{ height: "70px", maxHeight: "70px" }}
            href="https://www.celocamp.com/"
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/manos-accelerator.png"}
            imageAlt="manos accelerator"
            href="https://manosaccelerator.com/"
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/id-lab.png"}
            imageAlt="iD Lab"
            imageStyle={{ height: "70px", maxHeight: "70px" }}
            href="https://depaulidlab.com/"
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/bridge-for-billions.png"}
            imageAlt="Bridge for Billions"
            href="https://www.bridgeforbillions.org/"
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/entrepreneurs-organization.png"}
            imageAlt="Entrepreneurs Organization"
            imageStyle={{ height: "70px", maxHeight: "70px" }}
            href="https://www.eonetwork.org/"
          ></Client>
        </FourColumn>
        <CenteredHeading>Clients</CenteredHeading>
        <FourColumn>
          <Client
            imageSrc={"/assets/images/clients/bold.png"}
            imageAlt="BOLD digital marketing studio"
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/clientify.png"}
            imageAlt="Clientify"
            imageStyle={{ height: "70px", maxHeight: "70px" }}
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/rapid-innovation.png"}
            imageAlt="Rapid Innovation"
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/hispanic.png"}
            imageAlt="HIspanic Chamber of Commerce Metro Orlando"
            imageStyle={{ height: "100px", maxHeight: "100px" }}
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/crmx.png"}
            imageAlt="CRMX"
            imageStyle={{ height: "100px", maxHeight: "100px" }}
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/ape-unit.png"}
            imageAlt="Ape Unit"
            imageStyle={{ height: "40px", maxHeight: "40px" }}
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/glimpse.png"}
            imageAlt="Glimpse"
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/dacade.png"}
            imageAlt="Dacade"
            imageStyle={{ height: "70px", maxHeight: "70px" }}
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/vivas.png"}
            imageAlt="VIVAS"
            imageStyle={{ height: "70px", maxHeight: "70px" }}
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/esor.png"}
            imageAlt="Esor Consulting Group"
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/rico-foods-co.jpg"}
            imageAlt="RICO Foods Co"
            imageStyle={{ height: "70px", maxHeight: "70px" }}
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/u31.png"}
            imageAlt=""
            imageStyle={{ height: "70px", maxHeight: "70px" }}
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/u28.png"}
            imageAlt=""
            imageStyle={{ height: "70px", maxHeight: "70px" }}
          ></Client>
          <Client
            imageSrc={"/assets/images/clients/u36.jpg"}
            imageAlt=""
            imageStyle={{ height: "70px", maxHeight: "70px" }}
          ></Client>
        </FourColumn>
        <TwoColumn className="pb-4">
          <Service>
            <h1>Before You Get to Money</h1>
            <p>
              Before you get the first serious investment, you need to have
              certain things ready. Not just in your pitch deck, but in reality.
              A bootcamp, an incubator, or an accelerator can hone your skills.
              Let us get you ready for each step, and match you with the right
              partner.
            </p>
          </Service>
          <Service>
            <h1>The Road to Funding</h1>
            <p>
              No matter how you got started, what keeps you going, if you are
              solo or part of the team, it comes down to funding. Can you
              bootstrap, stay profitable, scale? Different forms of funding help
              in different ways.
            </p>
          </Service>
        </TwoColumn>
        <div style={{ textAlign: "center" }}>
          <a
            href="https://calendly.com/startup-iq"
            target="_blank"
            rel="noreferrer"
          >
            <Button>Book a free consultation with Michael</Button>
          </a>
        </div>
      </Container>
      <Footer></Footer>
    </AnimationRevealPage>
  );
}
