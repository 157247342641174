import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React from "react";
import { Link } from "components/link";
import { PurchaseProduct } from "components/products/PurchaseProduct";
import { PITCH_DECK } from "constants/products";
import { SharedLayout, SignUpWithRedirect } from "./Shared";

const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const FinePrint = tw.p`my-1 lg:my-2 text-sm`;

export const PitchDeckProductPage = () => {
  return (
    <SharedLayout>
      <>
        <Heading tw="text-primary-500">Pitch Deck</Heading>
        <Paragraph>
          If you got this far you probably know what a pitch deck is and why you
          need it. And you've probably finished a few Google searches and
          downloaded a few pitch decks. Which one did you get? The one Sequoia
          recommends or the Guy Kawasaki one?
        </Paragraph>
        <Paragraph>
          The truth is: one template doesn't make everyone happy. Sometimes you
          need one for a big VC, sometimes you need one just to apply to an
          accelerator or an incubator program.
        </Paragraph>
        <Paragraph>
          Regardless, downloading someone else's template doesn't account for
          where your business is (every startup stage has different components,
          and the expectations vary).
        </Paragraph>
        <Paragraph>
          Moreover, if your presentation is tomorrow, or your accelerator
          application is due in a week, you won't be able to come up with a
          solid pitch on a short notice.
        </Paragraph>
        <Paragraph>
          So we say, start your <strong>custom*</strong>{" "}
          <strong>pitch deck**</strong> now. Have it ready when you need it.
        </Paragraph>
        <PurchaseProduct
          type="pitchDeck"
          labelFn={({ price }) =>
            `Custom Pitch Deck - ${price.substring(0, price.length - 3)}`
          }
          productId={PITCH_DECK}
          expanded
        />
        <FinePrint>
          The Custom Pitch Deck product becomes available after you sign up or
          sign in to your free Startup IQ account.
        </FinePrint>

        <Paragraph>
          * This is a customizable version. You start with what you know, and we
          show you what you are missing. It is adjusted for the stage we
          determined through the <Link href="/">Startup IQ Score</Link> and your
          end goal.
        </Paragraph>
        <Paragraph>
          ** Certain applications ask you concepts for which you have no data
          yet. It could be the case that you don't have those concept very well
          defined. That's ok. You can ask those{" "}
          <Link href="/questions">Questions</Link>.
        </Paragraph>
      </>
      <SignUpWithRedirect />
    </SharedLayout>
  );
};
