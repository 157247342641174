import React, { useEffect, useState, createContext } from "react";

import { initializeApp, FirebaseApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";

const FirebaseContext = createContext<{
  app?: FirebaseApp;
  analytics?: Analytics;
}>({});

const Firebase = ({ children }: { children: React.ReactNode }) => {
  const [firebaseApp, setFirebaseApp] = useState<FirebaseApp | undefined>(
    undefined
  );
  const [firebaseAnalytics, setFirebaseAnalytics] = useState<
    Analytics | undefined
  >(undefined);

  useEffect(() => {
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    setFirebaseApp(app);

    const analytics = getAnalytics(app);
    setFirebaseAnalytics(analytics);
  }, []);

  return (
    <FirebaseContext.Provider
      value={{ app: firebaseApp, analytics: firebaseAnalytics }}
    >
      {firebaseApp && children}
    </FirebaseContext.Provider>
  );
};

export default Firebase;

export { FirebaseContext };
