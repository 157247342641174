import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/configureStore";
import { createApiCall } from "store/fetch";
import { UserModel } from "store/users/schemas";

const initialState: { authToken?: string; userId?: string; tags: string[] } = {
  tags: [],
};

export const authUser = createApiCall<UserModel, { tag?: string }>(
  "auth/authUser",
  "POST",
  (params) => {
    const qs = new URLSearchParams();
    if (params.tag) {
      qs.append("tag", params.tag);
    }

    return `/users/auth?${qs.toString()}`;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.authToken = action.payload;
    },
    signOut: (state) => {
      state.authToken = undefined;
      state.userId = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authUser.fulfilled, (state, action) => {
      state.userId = action.payload.id;
      state.tags = action.payload.tags;
    });
  },
});

export const { signOut, setToken } = authSlice.actions;

export const selectAuthToken = (state: RootState) => state.auth.authToken;

export const selectIsAuthenticated = (state: RootState) =>
  state.auth.userId !== undefined;

export const selectTags = (state: RootState) => state.auth.tags ?? [];

export default authSlice.reducer;
