export const ONE_QUESTION = "627dc5c82d4a3d3c1a9bf7a7";
export const THREE_QUESTIONS = "627fb8d0c238a50498ef9c89";
export const FIVE_QUESTIONS = "627fb8d1c238a50498ef9c8a";

export const PITCH_DECK = "62f1bc8ac3b1454fcfe68513";
export const FUNDING = "62f1bd10c3b1454fcfe68e2c";

export const COSMODROME_APPLICATION = "632a6b0ec3b1454fcfae1466";
export const JTBD_APPLICATION = "632a7182c3b1454fcfae8431";

export const FIVE_QUESTION_PRICE = 35;
export const PITCH_DECK_PRICE = 25;
export const FUNDING_PRICE = 50;
