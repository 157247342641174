import { QuestionAnswers } from "components/quiz/types";
import {
  QuizResponseAnswerModel,
  QuizSpecificationQuestionModel,
} from "store/quizzes/schemas";

export const getAnswerText = (
  questionAnswers: QuizResponseAnswerModel[],
  questionSpec?: QuizSpecificationQuestionModel
) => {
  let answerText = "";
  if (questionSpec?.type === "text") {
    answerText = questionAnswers.map((a) => a.value).join(", ");
  } else if (questionAnswers.length > 0) {
    answerText = questionAnswers
      .map((a) => {
        const option = questionSpec?.options.find((o) => o.id === a.id);
        return option?.type === "value" ? option.label : option?.response;
      })
      .filter((a) => a)
      .join(", ");
  } else {
    answerText = "N/A";
  }

  return answerText;
};

export const getMaxScore = (
  questions: QuizSpecificationQuestionModel[]
): number => {
  let total = 0;

  questions.forEach((q) => {
    const scores = q.options.map((o) => o.score);

    if (q.type === "discreteValues") {
      const positiveScores = scores.filter((s) => s > 0);

      if (positiveScores.length > 0) {
        total += positiveScores.reduce((a, b) => a + b, 0);
      } else {
        total += Math.max(...scores);
      }
    } else {
      total += Math.max(...scores);
    }
  });
  return total;
};

export const getCurrentScore = (
  answers: Partial<QuestionAnswers>,
  questions: QuizSpecificationQuestionModel[]
): number => {
  let total = 0;

  Object.keys(answers).forEach((questionId) => {
    const question = questions.find((q) => q.id === questionId);
    const answer = answers[questionId];
    if (question && answer) {
      answer.forEach((a) => {
        const option = question.options.find((o) => o.id === a.id);
        if (option) {
          total += option.score;
        }
      });
    }
  });

  return total;
};
