import Footer from "components/footers/footer";
import { Header } from "components/headers";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React from "react";
import { PitchDeckProductListing } from "components/products/ProductListings";

const OneColumn = tw.div`max-w-screen-xl mx-auto py-8 md:py-8`;
const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const List = tw.ol`list-decimal`;
const ListItem = tw.li`ml-8 my-2 text-base xl:text-lg`;

export const PitchDeckPage = () => {
  return (
    <AnimationRevealPage disabled>
      <Header></Header>
      <div tw="relative">
        <OneColumn>
          <div tw="text-center">
            <Heading>Build your pitch deck</Heading>
          </div>
          <Paragraph>
            In this section we'll guide through slides such as:
          </Paragraph>
          <List>
            <ListItem>You and your team</ListItem>
            <ListItem>The problem you are solving</ListItem>
            <ListItem>The solution you are proposing</ListItem>
            <ListItem>Why now</ListItem>
            <ListItem>Market size</ListItem>
            <ListItem>Competitive landscape</ListItem>
            <ListItem>
              Differentiation - why is your solution better than others
            </ListItem>
            <ListItem>Distribution and go-to-market (GTM)</ListItem>
            <ListItem>Metrics: rev, users, retention and OKRs</ListItem>
            <ListItem>Long-term vision</ListItem>
          </List>
        </OneColumn>

        <div tw="flex justify-center">
          <div tw="w-1/4">
            <PitchDeckProductListing />
          </div>
        </div>
      </div>
      <Footer></Footer>
    </AnimationRevealPage>
  );
};
