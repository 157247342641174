export type QuestionResponse = {
  body: string;
  created: string;
  modified: string;
  userId: string;
  user?: { name?: string };
};

export enum QuestionStatus {
  Draft = "draft",
  Submitted = "submitted",
}

export type QuestionModel = {
  id: string;
  profileId: string;
  body: string;
  responses: QuestionResponse[];
  created: string;
  modified: string;
  status: QuestionStatus;
};
