import tw from "twin.macro";
import { css } from "styled-components/macro";

type ScoreDisplayProps = {
  value: number;
};

export const ScoreDisplay = ({ value }: ScoreDisplayProps) => {
  return (
    <div tw="flex justify-center">
      <div tw="font-bold text-4xl md:text-4xl lg:text-5xl xl:text-6xl bg-primary-500 text-white px-12 py-4 my-4 rounded-lg inline-block text-center">
        {value}
      </div>
    </div>
  );
};
