import React from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";

import { css } from "styled-components/macro";
import Header, { LogoLink, NavLink, NavLinks, SubNavLink } from "./light.js";
import { useEffect, useState } from "react";
import { useFeatureFlags } from "hooks/useFeatureFlags";
import {
  AuthenticatedUser,
  useAuthenticatedUser,
} from "hooks/useAuthenticatedUser";
import { signOutCompletely } from "helpers/signOut";
import { getAuth } from "firebase/auth";

const SubLinks = () => (
  <div tw="flex flex-col bg-white">
    <SubNavLink href="/my-score">My Score</SubNavLink>
    <SubNavLink href="/my-questions">Questions</SubNavLink>
    <SubNavLink href="/my-pitch-deck">Pitch Deck</SubNavLink>
    <SubNavLink href="/my-funding">Funding</SubNavLink>
    <SubNavLink
      href="/"
      onClick={() => {
        signOutCompletely();
      }}
    >
      Sign Out
    </SubNavLink>
  </div>
);

const HeaderComponent = () => {
  const [user, setUser] = useState<AuthenticatedUser | undefined>(undefined);

  const { user: authenticatedUser } = useAuthenticatedUser({
    redirectToLogin: false,
  });

  useEffect(() => {
    if (authenticatedUser && !user) {
      setUser(authenticatedUser);
    }
  }, [authenticatedUser]);

  const featureFlags = useFeatureFlags();

  return (
    <Header
      roundedHeaderButton={false}
      links={[
        <NavLinks key={1}>
          <NavLink href="/#">Home</NavLink>
          <NavLink href="/about/">About</NavLink>
          <NavLink href="/why/">Why</NavLink>
          <NavLink href="/contact/">Contact</NavLink>
          {user ? (
            <NavLink
              href="/dashboard/"
              subLinks={featureFlags.showNavigation && <SubLinks />}
            >
              {user.displayName || user.email}
            </NavLink>
          ) : (
            <NavLink href="/auth/">Account</NavLink>
          )}
        </NavLinks>,
      ]}
      logoLink={<LogoLink href="/">Startup IQ</LogoLink>}
      className={"header-light"}
    ></Header>
  );
};

export default HeaderComponent;
