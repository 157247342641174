import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React from "react";
import { InlineWidget } from "react-calendly";
import { SharedLayout, SignUpWithRedirect } from "./Shared";

const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;

export const FreeCallProductPage = () => {
  return (
    <SharedLayout>
      <>
        <Heading tw="text-primary-500">Schedule a Free Call</Heading>
        <InlineWidget url="https://calendly.com/startup-iq" />
      </>
      <SignUpWithRedirect />
    </SharedLayout>
  );
};
