import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store/configureStore";
import { ProfileModel } from "./schemas";
import { createApiCall } from "store/fetch";
import { QuizResponseModel } from "store/quizzes/schemas";
import { CollectionResultModel } from "store/types";

const initialState: { current?: ProfileModel; all: ProfileModel[] } = {
  all: [],
};

export const fetchProfiles = createApiCall<CollectionResultModel<ProfileModel>>(
  "profiles/fetchProfiles",
  "GET",
  () => "/profiles"
);

export const fetchCurrentProfile = createApiCall<ProfileModel>(
  "profiles/fetchCurrentProfile",
  "GET",
  () => "/profiles/current"
);

export const updateCurrentProfile = createApiCall<
  ProfileModel,
  Partial<ProfileModel>
>("profiles/updateCurrentProfile", "PATCH", () => "/profiles/current", {
  getBody: (body) => body,
});

export const createQuizResponse = createApiCall<
  void,
  Partial<QuizResponseModel>
>(
  "profiles/createQuizResponse",
  "POST",
  () => "/profiles/current/quiz-responses",
  {
    getBody: (body) => body,
  }
);

export const profilesSlice = createSlice({
  name: "profiles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProfiles.fulfilled, (state, action) => {
      state.all = action.payload.items;
    });
    builder.addCase(fetchCurrentProfile.fulfilled, (state, action) => {
      state.current = action.payload;
    });
    builder.addCase(updateCurrentProfile.fulfilled, (state, action) => {
      state.current = action.payload;
    });
  },
});

export const selectCurrentProfile = (state: RootState) =>
  state.profiles.current;

export const selectProfiles = (state: RootState) => state.profiles.all;

export default profilesSlice.reducer;
