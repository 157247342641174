import { Table } from "components/table";
import React from "react";
import { ProfileModel } from "store/profiles/schemas";
import { selectQuizResponses } from "store/quizzes/slice";
import { useAppSelector } from "store/types";
import { QuizResponseDetails } from "./QuizResponseDetails";

type ProfileQuizzesProps = {
  profile?: ProfileModel;
};
export const ProfileQuizzes = ({ profile }: ProfileQuizzesProps) => {
  const responses = useAppSelector((state) =>
    selectQuizResponses(state, profile?.id)
  );

  if (!profile) {
    return <div>No profile</div>;
  }

  return (
    <div>
      <Table
        data={profile.scoreDimensions}
        columns={[
          { name: "Dimension", field: "dimension" },
          { name: "Min", field: "min" },
          { name: "Max", field: "max" },
          { name: "Value", field: "value" },
        ]}
      />

      {responses.map((r, i) => (
        <QuizResponseDetails response={r} key={i} />
      ))}
    </div>
  );
};
