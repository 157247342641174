import React, { useMemo } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";

import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Button = tw(PrimaryButtonBase)`flex-grow`;

const ButtonContainer = styled.div((props: { count: number }) => [
  tw`flex`,
  props.count === 1 ? tw`w-1/2` : null,
  props.count === 2 ? tw`w-1/2 mx-2` : null,
  props.count === 3 ? tw`w-1/3 mx-2` : null,
]);

type ButtonSectionProps = {
  backAllowed?: boolean;
  backEnabled?: boolean;
  skipAllowed?: boolean;
  nextEnabled?: boolean;
  onBack?: () => void;
  onNext: () => void;
  onSkip?: () => void;
};
export const ButtonSection = ({
  backAllowed,
  backEnabled,
  skipAllowed,
  nextEnabled,
  onBack,
  onNext,
  onSkip,
}: ButtonSectionProps) => {
  const buttonDefs = useMemo(() => {
    return [
      {
        allowed: backAllowed,
        enabled: backEnabled,
        onClick: onBack,
        label: "Back",
      },
      { allowed: true, enabled: nextEnabled, onClick: onNext, label: "Next" },
      {
        allowed: skipAllowed,
        enabled: true,
        onClick: onSkip,
        label: "Skip",
      },
    ].filter((def) => def.allowed);
  }, [
    backAllowed,
    backEnabled,
    nextEnabled,
    onBack,
    onNext,
    onSkip,
    skipAllowed,
  ]);

  return (
    <div tw="flex flex-row justify-center max-w-screen-xl mx-auto w-2/3 mt-8">
      {buttonDefs.map((b, i) => (
        <ButtonContainer key={i} count={buttonDefs.length}>
          <Button
            disabled={!b.enabled}
            onClick={(e) => {
              e.currentTarget.blur();
              if (b.onClick) {
                b.onClick();
              }
            }}
          >
            {b.label}
          </Button>
        </ButtonContainer>
      ))}
    </div>
  );
};
