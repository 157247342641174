import styled from "styled-components";
import tw from "twin.macro";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";

const OneColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-10 md:py-12`;

const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

export const OurGoals = () => {
  return (
    <OneColumn>
      <div tw="w-full text-center">
        <Heading tw="text-primary-500">Our Goals</Heading>
        <Paragraph tw="font-bold">
          1. Put your startup in the succesful 10%.
        </Paragraph>
        <Paragraph tw="font-bold">
          2. Reduce the 90% of failure as much as we can.
        </Paragraph>
      </div>
    </OneColumn>
  );
};
