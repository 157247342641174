import React from "react";
import { Transition, TransitionStatus } from "react-transition-group";

const transitionStyles: Record<TransitionStatus, React.CSSProperties> = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 },
};

type FadeInProps = {
  children?: React.ReactNode;
  duration: number;
};

export const FadeIn = ({ children, duration }: FadeInProps) => {
  return (
    <Transition appear in timeout={0}>
      {(state) => (
        <div
          style={{
            ...{
              transition: `opacity ${duration}ms ease-in-out`,
              opacity: 0,
            },
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};
