import Footer from "components/footers/footer";
import { Header } from "components/headers";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React, { useCallback } from "react";
import { useAppSelector } from "store/types";
import { Dimensions } from "components/profile/dimensions/Dimensions";
import { ProfileProducts } from "components/profile/products/ProfileProducts";
import { selectCurrentProfile } from "store/profiles/slice";
import { ProfileScore } from "components/profile/score/ProfileScore";
import { Stages } from "components/profile/stages/Stages";
import { Probabilities } from "components/profile/probabilities/Probabilities";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { signOutCompletely } from "helpers/signOut";

const Container = styled.div`
  ${tw`relative`}

  .profile-img-wrapper {
    text-align: center;
  }
  .profile-img {
    margin: 0 auto;
    height: 128px;
  }

  .card {
    ${tw`rounded-lg shadow-raised bg-white border-none`}
  }

  input[type="checkbox"] {
    ${tw`cursor-pointer`}
  }

  input[type="checkbox"]:checked {
    ${tw`bg-primary-500`}
  }
`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-baseline max-w-screen-xl mx-auto py-8 md:py-16`;
const LeftNarrowColumn = tw.div`relative lg:w-4/12 text-left max-w-lg mx-auto lg:max-w-none lg:text-left`;
const LeftColumn = tw.div`relative lg:w-6/12 text-left max-w-lg lg:max-w-none`;
const RightColumn = tw.div`relative w-full lg:w-6/12 mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;
const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const Button = tw.button`w-full inline-block bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-2 sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`;
const OneColumn = tw.div`max-w-screen-xl mx-auto py-8 md:py-8`;

export const ScorePage = () => {
  const { user } = useAuthenticatedUser();

  const userSignOut = useCallback(() => {
    signOutCompletely();
  }, []);

  const profile = useAppSelector(selectCurrentProfile);

  const hasScore = profile?.score !== undefined && profile?.score !== null;

  return (
    <AnimationRevealPage disabled>
      <Header></Header>
      <Container>
        <TwoColumn>
          <LeftNarrowColumn>
            {user ? (
              <div className="profile-img-wrapper">
                {user.photoURL ? (
                  <img
                    src={user.photoURL}
                    alt={user.displayName || "unknown"}
                    className="profile-img mb-2"
                  />
                ) : (
                  <div
                    className="iconsminds-male-female"
                    style={{ fontSize: "128px", textAlign: "center" }}
                  ></div>
                )}
                <Button onClick={userSignOut}>Sign out</Button>
              </div>
            ) : (
              <div
                className="iconsminds-male-female"
                style={{ fontSize: "128px", textAlign: "center" }}
              ></div>
            )}
          </LeftNarrowColumn>
          <RightColumn>
            <Heading>Try to keep your profile up-to-date.</Heading>
            <Paragraph>
              We look at how your needs match your level of readiness. Based on
              the characteristics of your profile, we match you with the right
              bootcamp, angel, incubator, or accelerator. However, the right
              next step might not be raising funds.
            </Paragraph>
            <Paragraph>Below, you can see where you are.</Paragraph>
          </RightColumn>
        </TwoColumn>

        <TwoColumn>
          <LeftColumn tw="mr-2">
            <Heading tw="text-center">Your Startup-IQ Score</Heading>
            <ProfileScore />
          </LeftColumn>
          <RightColumn tw="self-start lg:ml-2">
            <Heading tw="text-center">Your Startup-IQ Profile</Heading>
            <div tw="mt-4">
              {profile !== undefined && (
                <>
                  <Dimensions />
                  {!hasScore && (
                    <Paragraph tw="text-center">
                      Before we can calculate your score, you need to take the
                      test.
                    </Paragraph>
                  )}
                </>
              )}
            </div>
          </RightColumn>
        </TwoColumn>

        <OneColumn>
          <Heading>Breaking down your score</Heading>
          <Paragraph>
            If you have a TOTAL score of 48, or 12 points each for Team,
            Knowledge, and Funding, you are ready to exit the SEED stage. If you
            are below 36, you have to work on some basics. There&apos;s is no
            point in gaming this score. You have to do the work.
          </Paragraph>
          <Paragraph>
            Maybe you&apos;ve been thinking about external funding. It could be
            too early. It&apos;s rare when more money can help you make up for
            the lack of fundamentals. Not only it&apos;s increasingly harder to
            raise the funds, but once you have the dollars you&apos;ll probably
            end up wasting the investment on Entrepreneurship 101 activities.
          </Paragraph>
          <Paragraph>
            We recommend bootstrapping your way out of the SEED stage.
          </Paragraph>
        </OneColumn>

        <OneColumn>
          <Stages />
        </OneColumn>

        <OneColumn>
          <Probabilities />
        </OneColumn>

        <OneColumn>
          <ProfileProducts />
        </OneColumn>
      </Container>
      <Footer></Footer>
    </AnimationRevealPage>
  );
};
