import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro";

type ProductListingProps = {
  title: string;
  description: string;
  price: string;
  onClick?: () => void;
};
export const ProductListing = ({
  title,
  description,
  price,
  onClick,
}: ProductListingProps) => {
  const renderBookend = (text: string) => (
    <div tw="bg-primary-500 w-full rounded-sm text-white text-center text-xl font-bold p-4 group-hover:bg-secondary-500 group-active:bg-secondary-700">
      {text}
    </div>
  );

  return (
    <button
      className="group"
      tw="border-primary-500 border-[1px] rounded-md m-8 flex flex-col min-h-full lg:flex-1 lg:w-full hover:border-secondary-500 active:border-secondary-900"
      onClick={onClick}
    >
      {renderBookend(title)}
      <div tw="text-primary-500 text-center text-sm m-4 flex-1 text-lg group-hover:bg-white group-hover:text-secondary-500 group-active:text-secondary-900">
        {description}
      </div>
      {renderBookend(price)}
    </button>
  );
};
