import Footer from "components/footers/footer";
import { Header } from "components/headers";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React from "react";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { WhereAreYouNow } from "components/home/WhereAreYouNow";
import { WhatCanYouDo } from "components/home/WhatCanYouDo";

const Container = styled.div`
  ${tw`relative`}
`;

const Heading = tw.p`font-normal text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-gray-900 leading-tight`;
const OneColumn = tw.div`max-w-screen-xl mx-auto py-8 md:py-8`;

export const ProfileDashboardPage = () => {
  const { user } = useAuthenticatedUser();

  return (
    <AnimationRevealPage disabled>
      <Header></Header>
      <Container>
        <OneColumn>
          <Heading tw="text-primary-500 font-bold">Your Dashboard</Heading>
        </OneColumn>
        <OneColumn>
          <WhereAreYouNow
            showDetails={false}
            onClick={() => (window.location.href = "/my-score")}
          />
        </OneColumn>

        <OneColumn>
          <WhatCanYouDo page="dashboard" />
        </OneColumn>
      </Container>
      <Footer></Footer>
    </AnimationRevealPage>
  );
};
