import tw from "twin.macro";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import React, { useState } from "react";
import { useEffect } from "react";
import { useAppDispatch } from "store/types";
import { createQuestionResponse } from "store/questions/slice";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { QuestionStatus } from "store/questions/schemas";

const TextArea = tw.textarea`w-full h-16 resize-none border rounded border-primary-600 focus:outline-none transition duration-300 hocus:border-primary-500`;
const Button = tw.button`inline-block bg-primary-500 text-gray-100 font-bold my-4 sm:my-2 rounded-full py-2 sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`;

type AnswerQuestionProps = {
  questionId: string;
};

export const AnswerQuestion = ({ questionId }: AnswerQuestionProps) => {
  const [body, setBody] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useAppDispatch();

  return (
    <div>
      <TextArea
        name="text"
        value={body}
        maxLength={10000}
        onChange={(event) => setBody(event.target.value)}
        disabled={isSaving}
      />
      <Button
        onClick={() => {
          setIsSaving(true);
          dispatch(
            createQuestionResponse({
              questionId,
              body,
            })
          )
            .unwrap()
            .then(() => {
              setBody("");
              setIsSaving(false);
            })
            .catch(() => setIsSaving(false));
        }}
      >
        Answer Question
      </Button>
    </div>
  );
};
