import Footer from "components/footers/footer";
import SignUpForm from "components/forms/SignUpForm";
import { Header } from "components/headers";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import tw from "twin.macro";
import styled from "styled-components";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { css } from "styled-components/macro";
import { useCallback, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Quiz } from "components/quiz/Quiz";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;

const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0 md:w-5/12 mt-16 md:mt-0`;

const LeftColumn = styled(Column)(() => [
  tw`text-center max-w-lg mx-auto lg:max-w-none lg:text-left`,
  tw`md:mr-12 lg:mr-16`,
]);
const RightColumn = styled(Column)(() => [tw`md:ml-12 lg:ml-16`]);

export default function SignUpPage() {
  const onComplete = useCallback(
    () => (window.location.href = "/dashboard"),
    []
  );

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // TODO: Remove this, this is just a hack to add a small delay so that the Quiz results get saved correctly
        setTimeout(onComplete, 500);
      } else {
      }
    });
  }, []);

  return (
    <AnimationRevealPage disabled>
      <Header></Header>
      <Container>
        <TwoColumn>
          <LeftColumn>
            <SignUpForm onComplete={onComplete} />
          </LeftColumn>
          <RightColumn>
            <Quiz />
          </RightColumn>
        </TwoColumn>
      </Container>
      <Footer></Footer>
    </AnimationRevealPage>
  );
}
